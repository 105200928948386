/**
 * Hier kommt Code für Mitarbeiterstammdaten hin
 * - Personalien
 * - Beschaeftigung
 * - Lohnarten & Nettobezüge
 * - Zuschläge
 * - Postfach
 */

import _ from 'underscore';

import dayjs from '../../../shared/services/dayjs.js';
import basedataService from './services/basedataService.js';
import stammdatenService from './services/stammdatenService.js';
import SystemDialogService from '../../../shared/services/systemDialogService.js';
import systemNachrichtService from './services/systemNachrichtService.js';
import {
    addInputRow,
    collectValues,
    debounce,
    displayArray,
    displayValues,
    erstelleMitarbeiterListe,
    fuelleSelectOptionen,
    resetValidationMessages,
} from './util.js';

const debounceUpdateNachrichtMA = debounce(async (args) => {
    await speichereMitarbeiterNachricht(args[0]);
});

// Mitarbeiter Event Handlers
window.myHandlers = window.myHandlers || {};
window.myHandlers.speichereAbgaben = speichereAbgaben;
window.myHandlers.speichereBeschaeftigung = speichereBeschaeftigung;
window.myHandlers.speichereBeschaeftigungsArt = speichereBeschaeftigungsArt;
window.myHandlers.speichereMABezug = speichereMABezug;
window.myHandlers.speichereMALohnart = speichereMALohnart;
window.myHandlers.speichereMitarbeiterNotiz = speichereMitarbeiterNotiz;
window.myHandlers.speicherePersonalien = speicherePersonalien;
window.myHandlers.speichereZuschlaege = speichereZuschlaege;
window.myHandlers.updateMAQualifikation = updateMAQualifikation;
window.myHandlers.updateMitarbeiterPosten = updateMitarbeiterPosten;
window.myHandlers.updateMitarbeiterEinsatzort = updateMitarbeiterEinsatzort;
window.myHandlers.verwendeMABezug = verwendeMABezug;
window.myHandlers.verwendeMALohnart = verwendeMALohnart;
window.myHandlers.entferneMABezug = entferneMABezug;
window.myHandlers.entferneMALohnart = entferneMALohnart;
window.myHandlers.deleteMAQualifikation = deleteMAQualifikation;
window.myHandlers.neueMAQualifikation = neueMAQualifikation;
window.myHandlers.neueMitarbeiterNachricht = neueMitarbeiterNachricht;
window.myHandlers.debounceUpdateNachrichtMA = debounceUpdateNachrichtMA;
window.myHandlers.updateSollstundenMonat = updateSollstundenMonat;
window.myHandlers.updateSollstundenWoche = updateSollstundenWoche;
window.myHandlers.updateSollstundenTag = updateSollstundenTag;
window.myHandlers.updateSolltageWoche = updateSolltageWoche;
window.myHandlers.reaktiviereMitarbeiterClick = reaktiviereMitarbeiterClick;
window.myHandlers.deaktiviereMitarbeiterClick = deaktiviereMitarbeiterClick;
window.myHandlers.generiereAktivierungsCode = generiereAktivierungsCode;
window.myHandlers.beantrageSofortmeldung = beantrageSofortmeldung;
window.myHandlers.storniereSofortmeldung = storniereSofortmeldung;
window.myHandlers.inputIBAN = inputIBAN;
window.myHandlers.inputRVNr = inputRVNr;
window.myHandlers.clickZurBenutzerverwaltung = clickZurBenutzerverwaltung;
window.myHandlers.wechsleBeschaeftigungMA = wechsleBeschaeftigungMA;
window.myHandlers.confirmWechselBeschaeftigungMA = confirmWechselBeschaeftigungMA;

const debounceSpeichereMA = debounce(async (args) => {
    await stammdatenService.updateMitarbeiterdaten(args[0]);
    // Falls die Betriebsstätte gewechselt wird, müssen wir die Beschäftigung neu laden.
    if (args[1]) {
        ladeBeschaeftigung(stammdatenService.aktuellerMitarbeiter, stammdatenService.unternehmensobjekt);
    }
    // validierung entsprechend des Mitarbeiter Reiters
    switch (window.myVars.websiteStatus) {
        case 'ma-personalien':
            validatePersonalien(stammdatenService.aktuellerMitarbeiter);
            break;
        case 'ma-beschaeftigung':
            validateBeschaeftigung(stammdatenService.aktuellerMitarbeiter);
            break;
        case 'ma-zuschlaege':
            validateZuschlaege(stammdatenService.aktuellerMitarbeiter);
            break;
        case 'ma-abgaben':
            validateAbgaben(stammdatenService.aktuellerMitarbeiter);
            break;
        case 'ma-zeitkonto':
            validateZeitkonto(stammdatenService.aktuellerMitarbeiter);
            break;
        default:
            break;
    }
    await erstelleMitarbeiterListe(stammdatenService.aktuelleBetriebsstaette);
}, 1000);

// onclick handler für das Reaktivieren eines Mitarbeiters
async function reaktiviereMitarbeiterClick() {
    const input = await SystemDialogService.instance.displayAsync('ma-aktivieren-dialog');
    if (!input.success) {
        return;
    }
    const result = await stammdatenService.reaktivereMitarbeiter(stammdatenService.aktuellerMitarbeiter._id);
    if (!result) {
        return;
    }
    updateLoginStatusButtons(true);
}

// onclick handler für das Deaktivieren eines Mitarbeiters
async function deaktiviereMitarbeiterClick() {
    const input = await SystemDialogService.instance.displayAsync('ma-deaktivieren-dialog');
    if (!input.success) {
        return;
    }
    const result = await stammdatenService.deaktivereMitarbeiter(stammdatenService.aktuellerMitarbeiter._id);
    if (!result) {
        return;
    }
    updateLoginStatusButtons(false);
}

function validateZeitkonto(aktuellerMA) {
    resetValidationMessages();
    const htmlParent = document.getElementById('ma-Zeitkonto');
    const validateResults = aktuellerMA.Errors;
    validateResults.forEach((error) => {
        const htmlElement = htmlParent.querySelector(`[aria-label="${error.instancePath}"]`);
        if (htmlElement) {
            htmlElement.innerText = error.message;
        }
    });
}

/**
 * Speichert die aktuellen Mitarbeiterdaten, falls es eine Änderung gibt.
 */
async function forceUpdateMA() {
	// Falls es Änderungen im Mitarbeiter Objekt gibt, müssen wir erst speichern und den debounce entfernen...
	if (window.myVars.mitarbeiterEditiert && !_.isEmpty(stammdatenService.aktuellerMitarbeiter)) {
		await stammdatenService.updateMitarbeiterdaten(stammdatenService.aktuellerMitarbeiter);
		clearTimeout(window.myVars.lastDebounceId);
	}
}

// Onclick Handler für das Speichern von Mitarbeiter Personalien
async function speicherePersonalien() {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    if (_.isEmpty(aktuellerMA)) {
        systemNachrichtService.zeigeKleineNachricht('Kein Mitarbeiter ausgewählt!', 0);
        return;
    }
    const htmlDiv = document.getElementById('ma-Personalien');
    collectValues(aktuellerMA.Personalien, 'ma-', htmlDiv);
    const nationalitaetSelect = document.querySelector('[aria-label="ma-Staatsangehoerigkeit"]');
    const geburtSelect = document.querySelector('[aria-label="ma-Geburtsland"]');
    aktuellerMA.Personalien.Staatsangehoerigkeit.Nationenschluessel = nationalitaetSelect.value;
    if (nationalitaetSelect.selectedIndex >= 0) {
        aktuellerMA.Personalien.Staatsangehoerigkeit.Nationalitaet = nationalitaetSelect.options[nationalitaetSelect.selectedIndex].innerText;
    }
    aktuellerMA.Personalien.Geburtsland.Nationenschluessel = geburtSelect.value;
    if (geburtSelect.selectedIndex >= 0) {
        aktuellerMA.Personalien.Geburtsland.Staat = geburtSelect.options[geburtSelect.selectedIndex].innerText;
    }
    window.myVars.mitarbeiterEditiert = true;
    debounceSpeichereMA(aktuellerMA);
}

function validatePersonalien(aktuellerMA) {
    const htmlDiv = document.getElementById('ma-Personalien');
    resetValidationMessages();
    const validateResults = aktuellerMA.Errors;
    validateResults.forEach((error) => {
        const htmlElement = htmlDiv.querySelector(`[aria-label="${error.instancePath}"]`);
        if (htmlElement) {
            htmlElement.innerText = error.message;
        }
    });
}

// Onclick Handler für das Speichern von Mitarbeiter Zuschlägen
async function speichereZuschlaege() {
    // TODO: Wähle die entsprechende Beschäftigung
    const bIndex = 0;
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    if (_.isEmpty(aktuellerMA)) {
        systemNachrichtService.zeigeKleineNachricht('Kein Mitarbeiter ausgewählt!', 0);
        return;
    }
    const htmlDiv = document.getElementById('ma-Zuschlaege');
    collectValues(aktuellerMA.Beschaeftigung[bIndex].Zuschlaege, 'ma-', htmlDiv);
    // Falls N6 in Höhe von N1 true ist, dann wollen wir N6Prozent in der gleichen Höhe wie N1Prozent haben, sonst 0
    aktuellerMA.Beschaeftigung[bIndex].Zuschlaege.N6Prozent = aktuellerMA.Beschaeftigung[bIndex].Zuschlaege.N6Prozent ? aktuellerMA.Beschaeftigung[bIndex].Zuschlaege.N1Prozent : 0;
    window.myVars.mitarbeiterEditiert = true;
    debounceSpeichereMA(aktuellerMA);
}

function validateZuschlaege(aktuellerMA) {
    const htmlDiv = document.getElementById('ma-Zuschlaege');
    resetValidationMessages();
    const validateResults = aktuellerMA.Errors;
    validateResults.forEach((error) => {
        const htmlElement = htmlDiv.querySelector(`[aria-label="${error.instancePath}"]`);
        if (htmlElement) {
            htmlElement.innerText = error.message;
        }
    });
}

/**
 * Bestückt die HTML Oberfläche der Mitarbeiter Personalien.
 * @param {object} aktuellerMA aktuell ausgewählter Mitarbeiter
 */
async function ladePersonalien(aktuellerMA) {
    if (!_.isEmpty(aktuellerMA)) {
        const laender = await basedataService.holeLaenderBasedataProvider();
        const geburtSelect = document.querySelector('[aria-label="ma-Geburtsland"]');
        const nationalitaetSelect = document.querySelector('[aria-label="ma-Staatsangehoerigkeit"]');
        fuelleSelectOptionen(geburtSelect, laender, 'Nationenschluessel', 'Staat');
        fuelleSelectOptionen(nationalitaetSelect, laender, 'Nationenschluessel', 'Staatsangehoerigkeit');
        const parentHtml = document.getElementById('ma-Personalien');
        displayValues(aktuellerMA.Personalien, '', parentHtml, 'ma-');
        geburtSelect.value = aktuellerMA.Personalien.Geburtsland?.Nationenschluessel;
        nationalitaetSelect.value = aktuellerMA.Personalien.Staatsangehoerigkeit?.Nationenschluessel;
        validatePersonalien(aktuellerMA);
    } else {
        await SystemDialogService.instance.displayAsync('kein-ma-alert-dialog');
    }
}

/**
 * Zeigt die eingetragenen Zuschläge des Mitarbeiters an
 * @param {object} aktuellerMA aktuell ausgewählter Mitarbeiter
 */
async function ladeZuschlaege(aktuellerMA) {
    // TODO: Zeige alle Beschaeftigungen??
    if (!_.isEmpty(aktuellerMA)) {
        displayValues(aktuellerMA.Beschaeftigung[0].Zuschlaege, '', document.getElementById('ma-Zuschlaege'), 'ma-');
        validateZuschlaege(aktuellerMA);
    } else {
        await SystemDialogService.instance.displayAsync('kein-ma-alert-dialog');
    }
}

/**
 * Bestückt die HTML Oberfläche der Mitarbeiter Beschäftigung.
 * @param {object} aktuellerMA aktuell ausgewählter Mitarbeiter
 * @param {object} unternehmen das unternehmen des Kunden
 */
async function ladeBeschaeftigung(aktuellerMA, unternehmen) {
    if (!_.isEmpty(aktuellerMA)) {
        // Fülle Betriebsstätte Select
        const betriebSelect = document.querySelector('[aria-label="ma-BetriebsstaetteID"]');
        fuelleSelectOptionen(betriebSelect, unternehmen.Betriebsstaette, '_id', 'BetriebsstaetteName');
        // Fülle Beschäftigungsarten Select
        const beschArten = await basedataService.holeBeschaeftigungsartenBasedataProvider();
        const beschSelect = document.querySelector('[aria-label="ma-Beschaeftigungsart"]');
        fuelleSelectOptionen(beschSelect, beschArten, 'BeschID', ['BeschaeftigungsartKurz', 'Personengruppenschluessel']);
        const befristungen = await basedataService.holeBefristungsgrundBasedataProvider();
        const befristungSelect = document.querySelector('[aria-label="ma-Befristungsgrund"]');
        fuelleSelectOptionen(befristungSelect, befristungen, 'BefristungID', 'Kurz');
        const pausenmodelle = unternehmen.Pausenmodelle;
        const pausenmodellSelect = document.querySelector('[aria-label="ma-Pausenmodell"]');
        fuelleSelectOptionen(pausenmodellSelect, pausenmodelle, '_id', 'Bezeichnung', true, 'keins');
        // TODO: Beschaeftigung auslesen...
        const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
        // Betriebsstätte dynamisch von der Beschäftigung
        const aktuelleBS = unternehmen.Betriebsstaette.find((bs) => bs._id === aktuelleBeschaeftigung.BetriebsstaetteID);
        const htmlParent = document.getElementById('ma-Beschaeftigung');

        htmlParent.querySelector('[aria-label="ma-Personalnummer"]').value = aktuellerMA.Personalien.Personalnummer;
        displayValues(aktuelleBeschaeftigung, '', htmlParent, 'ma-');
        // Zeige die Posten des Mitarbeiters an inklusive checkboxen für die Zuordnung
        const postenListe = htmlParent.querySelector('[aria-label="ma-Posten"]');
        postenListe.innerHTML = '';
        if (aktuelleBS !== undefined) {
            aktuelleBS.Posten.forEach((posten) => {
                if (posten.Verwenden) {
                    const template = document.querySelector('[ma-posten-template]');
                    const neuerPosten = template.content.cloneNode(true).children[0];
                    neuerPosten.querySelector('[aria-label="ma-Posten-Bezeichnung"]').innerText = posten.Posten;
                    neuerPosten.querySelector('[aria-label="ma-Posten-checkbox"]').id = posten._id;
                    const isAktiv = aktuelleBeschaeftigung.Posten.findIndex((b) => b.PostenID === posten._id);
                    if (isAktiv >= 0) {
                        neuerPosten.querySelector('[aria-label="ma-Posten-checkbox"]').checked = true;
                    } else {
                        neuerPosten.querySelector('[aria-label="ma-Posten-checkbox"]').checked = false;
                    }
                    postenListe.appendChild(neuerPosten);
            }
            });
        }
        // Zeige die Einsatzorte des Mitarbeiters an inklusive checkboxen für die Zuordnung
        const einsatzortListe = htmlParent.querySelector('[aria-label="ma-Einsatzorte"]');
        einsatzortListe.innerHTML = '';
        if (aktuelleBS !== undefined) {
            aktuelleBS.Einsatzorte.forEach((einsatzort) => {
                const template = document.querySelector('[ma-einsatzorte-template]');
                const newEinsatzort = template.content.cloneNode(true).children[0];
                newEinsatzort.querySelector('[aria-label="ma-Einsatzort-Bezeichnung"]').innerText = einsatzort.Bezeichnung;
                newEinsatzort.querySelector('[aria-label="ma-Einsatzort-checkbox"]').id = einsatzort._id;
                const isAktiv = aktuelleBeschaeftigung.Einsatzorte.findIndex((eo) => eo.EinsatzortID === einsatzort._id);
                if (isAktiv >= 0) {
                    newEinsatzort.querySelector('[aria-label="ma-Einsatzort-checkbox"]').checked = true;
                } else {
                    newEinsatzort.querySelector('[aria-label="ma-Einsatzort-checkbox"]').checked = false;
                }
                einsatzortListe.appendChild(newEinsatzort);
            });
        }
        // Zeige die Qualifikation/Vergütung des Mitarbeiters an
        ladeQualifikationen(aktuelleBeschaeftigung);

        // Zeige die Regelarbeitszeiten des Mitarbeiters an
        const regeltage = aktuelleBeschaeftigung.Regelarbeitszeiten;
        const htmlTage = htmlParent.querySelectorAll('.regeltag');
        htmlTage.forEach((tag, index) => {
            const tagVon = tag.querySelector('[aria-label="regelzeit-von"]');
            const tagBis = tag.querySelector('[aria-label="regelzeit-bis"]');
            tagVon.value = regeltage[index].Von;
            tagBis.value = regeltage[index].Bis;
        });
        validateBeschaeftigung(aktuellerMA);
    } else {
        await SystemDialogService.instance.displayAsync('kein-ma-alert-dialog');
    }
}

/**
 * Ladet die Liste der Qualifikationen neu
 * @param {object} aktuelleBeschaeftigung des Mitarbeiters
 */
function ladeQualifikationen(aktuelleBeschaeftigung) {
    const qualis = aktuelleBeschaeftigung.Verguetung;
    const qualisParent = document.querySelector('[aria-label="ma-qu-Verguetung"]');
    displayArray(qualis, 'ma-qu-', qualisParent);
    updateEffektivlohn(aktuelleBeschaeftigung.SollstundenMonat);
}

/**
 * Speichert die Mitarbeiter Beschäftigungsdaten aus der Oberfläche.
 * Beim Wechsel der Betriebsstätte wird der boolean betriebWechsel auf true gesetzt.
 * Im Fall, dass der Mitarbeiter den Betrieb wechselt wird HTML Beschäftigung neu bestückt, um Posten und Einsatzorte anzuzeigen.
 * @param {boolean} betriebWechsel bestimmt ob der Mitarbeiter die Betriebsstätte wechselt.
 */
async function speichereBeschaeftigung(betriebWechsel = false) {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    if (!_.isEmpty(aktuellerMA)) {
        // TODO: Beschaeftigung auslesen...
        const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
        const htmlParent = document.getElementById('ma-Beschaeftigung');
        collectValues(aktuelleBeschaeftigung, 'ma-', htmlParent);
        aktuellerMA.Personalien.Personalnummer = htmlParent.querySelector('[aria-label="ma-Personalnummer"]').value;
        // Speichere die Regelarbeitszeiten des Mitarbeiters
        const regeltage = aktuelleBeschaeftigung.Regelarbeitszeiten;
        const htmlTage = htmlParent.querySelectorAll('.regeltag');
        htmlTage.forEach((tag, index) => {
            const tagVon = tag.querySelector('[aria-label="regelzeit-von"]');
            const tagBis = tag.querySelector('[aria-label="regelzeit-bis"]');
            regeltage[index].Von = tagVon.value;
            regeltage[index].Bis = tagBis.value;
            // Falls es eine von/bis Zeit gibt, wird der Tag als Regelarbeitstag vermerkt!
            if (tagVon.value !== '' || tagBis.value !== '') {
                aktuelleBeschaeftigung.Regelarbeitstage[index] = true;
            } else {
                aktuelleBeschaeftigung.Regelarbeitstage[index] = false;
            }
        });
        // Wenn wir die Betriebsstätte Wechseln, müssen wir die zugewiesenen Posten resetten, sonst enstehen ungewünschte Sideeffects.
        if (betriebWechsel) {
            aktuelleBeschaeftigung.Posten = [];
        }
        berechneUrlaubsanspruch(aktuelleBeschaeftigung);
        window.myVars.mitarbeiterEditiert = true;
        debounceSpeichereMA(aktuellerMA, betriebWechsel);
    }
}

function validateBeschaeftigung(aktuellerMA) {
    resetValidationMessages();
    const htmlParent = document.getElementById('ma-Beschaeftigung');
    const validateResults = aktuellerMA.Errors;
    validateResults.forEach((error) => {
        // Für die Regelarbeitszeiten müssen wir 2x7 messages zeigen...
        if (error?.instancePath?.includes('Regelarbeitszeiten')) {
            const htmlElement = htmlParent.querySelector('[aria-label="/Beschaeftigung/0/Regelarbeitszeiten"]');
            htmlElement.innerText = error.message;
            return;
        }
        if (error?.instancePath?.includes('Verguetung/')) {
            const htmlElement = htmlParent.querySelector(`[aria-label="${error.instancePath.replace(error.instancePath.split('/')[4], '__index__')}"]`);
            if (htmlElement) {
                htmlElement.innerText = error.message;
                return;
            }
        }
        const htmlElement = htmlParent.querySelector(`[aria-label="${error.instancePath}"]`);
        if (htmlElement) {
            htmlElement.innerText = error.message;
        }
    });
}

/**
 * Speichert die neue Zuordnung zu einem Posten ab.
 * @param {HTMLElement} thisElement Checkbox Element der Zuordnung des Postens auf dem geklickt wurde.
 */
async function updateMitarbeiterPosten(thisElement) {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    if (!_.isEmpty(aktuellerMA)) {
        // TODO: Beschaeftigung auslesen...
        const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
        const bIndex = aktuelleBeschaeftigung.Posten.findIndex((b) => b.PostenID === thisElement.id);
        // Wenn es gesetzt wurde fügen wir den Posten dem Mitarbeiter hinzu
        if (thisElement.checked && bIndex < 0) {
            aktuelleBeschaeftigung.Posten.push({ PostenID: thisElement.id });
            await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
        } else if (!thisElement.checked && bIndex >= 0) {
            // Wir entfernen den Posten aus dem Mitarbeiter Objekt...
            aktuelleBeschaeftigung.Posten.splice(bIndex, 1);
            await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
        } else {
            // Falls wir Inkonsistenzen haben, speichern wir nicht...
            systemNachrichtService.zeigeKleineNachricht('Posten konnten nicht aktualisiert werden!', -1);
        }
        validateBeschaeftigung(stammdatenService.aktuellerMA);
        await erstelleMitarbeiterListe();
    }
}

/**
 * Speichert die neue Zuordnung zu einem Einsatzort ab.
 * @param {HTMLElement} thisElement Checkbox Element der Zuordnung des Einsatzorts auf dem geklickt wurde.
 */
async function updateMitarbeiterEinsatzort(thisElement) {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    if (!_.isEmpty(aktuellerMA)) {
        // TODO: Beschaeftigung auslesen...
        const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
        const eoIndex = aktuelleBeschaeftigung.Einsatzorte.findIndex((eo) => eo.EinsatzortID === thisElement.id);
        // Wenn es gesetzt wurde fügen wir den Einsatzort dem Mitarbeiter hinzu
        if (thisElement.checked && eoIndex < 0) {
            aktuelleBeschaeftigung.Einsatzorte.push({ EinsatzortID: thisElement.id });
            await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
        } else if (!thisElement.checked && eoIndex >= 0) {
            // Wir entfernen den Einsatzort aus dem Mitarbeiter Objekt...
            aktuelleBeschaeftigung.Einsatzorte.splice(eoIndex, 1);
            await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
        } else {
            // Falls wir Inkonsistenzen haben, speichern wir nicht...
            systemNachrichtService.zeigeKleineNachricht('Einsatzorte konnten nicht aktualisiert werden!', -1);
        }
        validateBeschaeftigung(stammdatenService.aktuellerMA);
    }
}

/**
 * Bestückt die Mitarbeiter Übersicht mit Daten des aktuellen Mitarbeiters
 * @param {object} aktuellerMA
 * @param {object} unternehmen
 */
async function ladeUebersicht(aktuellerMA, unternehmen) {
    if (!_.isEmpty(aktuellerMA)) {
        // Mitarbeiter Notizen und Nachrichten laden...
        document.getElementById('ma-notizen').value = stammdatenService.aktuellerMitarbeiter.Uebersicht.Notizen;
        ladeMitarbeiterNachrichten(aktuellerMA.Uebersicht.Nachrichten);
        // TODO: Beschaeftigung auslesen...
        const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
        const htmlParent = document.getElementById('ma-Uebersicht');
        displayValues(aktuellerMA.Uebersicht, '', htmlParent, 'ma-');
        displayValues(aktuelleBeschaeftigung, '', htmlParent, 'ma-');
        displayValues(aktuellerMA.Personalien, '', htmlParent, 'ma-');
        // Qualifikationen anzeigen
        const qualiHTML = htmlParent.querySelector('[aria-label="ma-Qualifikation"]');
        qualiHTML.innerText = '';
        aktuelleBeschaeftigung.Verguetung.forEach((quali, index) => {
            const qualifikation = unternehmen.Qualifikationen.find((q) => q._id === quali.QualifikationID);
            qualiHTML.innerText += `${index === 0 ? '' : ', '}${qualifikation?.BezeichnungNeutral}`;
        });
        // Zugeordnete Posten anzeigen
        const postenHTML = htmlParent.querySelector('[aria-label="ma-Posten"]');
        postenHTML.innerText = '';
        let postenMatchCounter = 0;
        // Wähle aktuelle Betriebsstätte des Mitarbeiters
        const aktuelleBS = unternehmen.Betriebsstaette.find((bs) => bs._id === aktuelleBeschaeftigung.BetriebsstaetteID);
        htmlParent.querySelector('[aria-label="ma-BetriebsstaetteName"]').innerText = aktuelleBS.BetriebsstaetteName;
        aktuelleBeschaeftigung.Posten.forEach((posten) => {
            const postenCounter = aktuelleBS.Posten.find((b) => b._id === posten.PostenID);
            if (postenCounter !== undefined) {
                postenHTML.innerText += `${postenMatchCounter === 0 ? '' : ', '}${postenCounter.Posten}`;
                postenMatchCounter += 1;
            }
        });
        if (postenMatchCounter === 0) {
            postenHTML.innerText = 'keine Posten';
        }
        // Regelarbeitstage anzeigen (abhängig von den eingegebenen Regelarbeitszeiten in der Beschäftigung)
        const tage = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
        const regeltage = htmlParent.querySelector('[aria-label="ma-Regelarbeitstage"]');
        regeltage.innerText = '';
        aktuelleBeschaeftigung.Regelarbeitstage.forEach((tag, index) => {
            if (tag) {
                regeltage.innerText += `${index === 0 || regeltage.innerText === '' ? '' : ', '}${tage[index]}`;
            }
        });
        // Verfügbare Wunschtage anzeigen
        const verfuegbar = htmlParent.querySelector('[aria-label="ma-Verfuegbarkeit"]');
        verfuegbar.innerText = '';
        aktuellerMA.Uebersicht.Verfuegbarkeit.forEach((tag, index) => {
            if (tag) {
                verfuegbar.innerText += `${index === 0 || verfuegbar.innerText === '' ? '' : ', '}${tage[index]}`;
            }
        });
        // Beschäftigungsart anzeigen
        const beschArten = await basedataService.holeBeschaeftigungsartenBasedataProvider();
        const beschaeftigungsart = htmlParent.querySelector('[aria-label="ma-Beschaeftigungsart"]');
        const tmpBA = beschArten.find((ba) => ba.BeschID === aktuelleBeschaeftigung.Beschaeftigungsart)?.BeschaeftigungsartKurz;
        beschaeftigungsart.innerText = !tmpBA ? '' : tmpBA;
        // Wunschzeiten anzeigen
        const wunschzeiten = aktuellerMA.Uebersicht.Wunschzeiten;
        if (!_.isEmpty(wunschzeiten)) {
            htmlParent.querySelector('[aria-label="ma-Wunschzeiten-ZeitraumVon"]').innerText = `${dayjs(wunschzeiten.ZeitraumVon).format('DD.MM.YY')} - `;
            htmlParent.querySelector('[aria-label="ma-Wunschzeiten-ZeitraumBis"]').innerText = dayjs(wunschzeiten.ZeitraumBis).format('DD.MM.YY');
            htmlParent.querySelector('[aria-label="ma-Wunschzeiten-ZeitraumPositiv"]').innerHTML = ` ${wunschzeiten.ZeitraumPositiv ? '<i class="bi bi-hand-thumbs-up-fill"></i>' : '<i class="bi bi-hand-thumbs-down-fill"></i>'}`;
        }
        // User Login Status anzeigen
        updateLoginStatusButtons(aktuellerMA.Uebersicht.LoginStatus);
        // Sofortmeldung anzeigen
        ladeSofortmeldung(aktuellerMA);
        // Mitarbeiter Zeiterfassung Aktivierungscode
        await ladeAktivierungsCode(aktuellerMA._id);

        // Mitarbeiter Benutzerinfo?
        const userRollenHtml = htmlParent.querySelector('#ma-user-rollen');
        userRollenHtml.style.display = 'none';    
        if (aktuellerMA.Uebersicht.LoginStatus) {
            const benutzerListe = await stammdatenService.ladeInterneMitarbeiter();
            const benutzer = benutzerListe.find((benutzer) => benutzer.attributes.neolohnId[0] === aktuellerMA._id);
            if (benutzer) {
                const benutzerRollen = benutzer.neolohnRoles.filter((rolle) => rolle !== 'mitarbeiter');
                if (benutzerRollen.length >= 1) {
                    htmlParent.querySelector('[aria-label="ma-UserRollen"').innerText = benutzerRollen.join(',\n');
                    userRollenHtml.style.display = 'grid';
                }
            }
        }
    } else {
        await SystemDialogService.instance.displayAsync('kein-ma-alert-dialog');
    }
}

/**
 * Click auf den Benutzerverwaltungsbutton leitet den User zur Benutzerverwaltung
 */
function clickZurBenutzerverwaltung() {
    window.myHandlers.topmenuClick(document.getElementById('Verwaltung'));
    window.myHandlers.submenuClick(document.getElementById('Benutzer'));
}

/**
 * Lädt/Aktualisiert die Statusanzeige der Sofortmeldung des aktuellen Mitarbeiters
 * @param {object} aktuellerMA
 */
function ladeSofortmeldung(aktuellerMA) {
    const sectionHTML = document.getElementById('ma-sofortmeldung-section');
    const sofortmeldungMA = aktuellerMA.Beschaeftigung[0].Sofortmeldung;
    const keineSofortmeldung = _.isEmpty(sofortmeldungMA?.Results) || _.isEmpty(sofortmeldungMA?.TAN);
    // Anzeigen des Sofortmelde Buttons nur falls es noch keine Sofortmeldung gab oder ein Storno vorliegt.
    sectionHTML.querySelector('.verwenden-button').style.display = keineSofortmeldung || sofortmeldungMA?.Status === 'storniert' ? 'block' : 'none';
    // Anzeige des Stornieren Buttons nur falls eine Stornierbare Sofortmeldung vorliegt.
    sectionHTML.querySelector('.entfernen-button').style.display = sofortmeldungMA?.Stornierbar ? 'block' : 'none';
    // reset info
    [...sectionHTML.querySelectorAll('.sofortmeldung-details')].forEach((item) => item.remove());
    if (!keineSofortmeldung) {
        const sofortmeldungTemplate = sectionHTML.querySelector('[ma-sofortmeldung-template]');
        const sofortmeldungDetails = sofortmeldungTemplate.content.cloneNode(true);
        displayValues(sofortmeldungMA, '', sofortmeldungDetails, 'ma-Sofortmeldung-');
        sectionHTML.appendChild(sofortmeldungDetails);
        if (sofortmeldungMA?.Status === 'storniert') {
            // Stornierungsfelder sichtbar machen
            [...sectionHTML.querySelectorAll('.ma-sm-storniert')].forEach((item) => { item.style.display = 'grid'; });
        } else {
            [...sectionHTML.querySelectorAll('.ma-sm-storniert')].forEach((item) => { item.style.display = 'none'; });
        }
    }
}

function updateLoginStatusButtons(loginStatus) {
    document.querySelector('[aria-label="ma-LoginStatus"]').innerText = loginStatus ? 'aktiv' : 'inaktiv';
    document.querySelector('[aria-label="ma-login-reaktivieren"]').style.display = loginStatus ? 'none' : 'block';
    document.querySelector('[aria-label="ma-login-deaktivieren"]').style.display = loginStatus ? 'block' : 'none';
}

/**
 * Bestückt die Abgaben Oberfläche mit relevanten Werten des Mitarbeiters
 * @param {object} aktuellerMA
 */
async function ladeAbgaben(aktuellerMA) {
    if (!_.isEmpty(aktuellerMA)) {
        // TODO: Beschaeftigung auslesen...
        const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
        // Konfessionen laden...
        const konfessionen = await basedataService.holeKonfessionBasedataProvider();
        let konfessionSelect = document.querySelector('[aria-label="ma-KonfessionArbeitnehmer"]');
        fuelleSelectOptionen(konfessionSelect, konfessionen, 'Konfessionskuerzel', 'Konfessionsbezeichnung');
        konfessionSelect = document.querySelector('[aria-label="ma-KonfessionEhegatte"]');
        fuelleSelectOptionen(konfessionSelect, konfessionen, 'Konfessionskuerzel', 'Konfessionsbezeichnung');
        // Krankenkassen laden...
        const krankenkassen = await basedataService.holeKrankenkassenBasedataProvider();
        const krankenkasseSelect = document.querySelector('[aria-label="ma-Krankenkasse-KrankenkasseID"]');
        fuelleSelectOptionen(krankenkasseSelect, krankenkassen, 'Betriebsnummer', ['Kurzbezeichnung', 'Betriebsnummer']);
        krankenkasseSelect.value = aktuelleBeschaeftigung.Krankenkasse.Betriebsnummer;
        // Steuerdaten laden...
        const htmlParentSteuer = document.getElementById('ma-Steuer');
        displayValues(aktuelleBeschaeftigung.Steuer, '', htmlParentSteuer, 'ma-');
        // lade Unfallversicherung
        displayValues(aktuelleBeschaeftigung.Berufsgenossenschaft, '', htmlParentSteuer, 'ma-Berufsgenossenschaft-');
        // lade Sozialversicherungsdaten
        const htmlParentSozial = document.getElementById('ma-Sozialabgaben');
        displayValues(aktuelleBeschaeftigung.Sozialversicherung, '', htmlParentSozial, 'ma-');
        // lade Versicherungen/Krankenkasse
        const htmlParentVersicherung = document.getElementById('ma-Versicherungen');
        // Krankenversicherungsnummer laden
        displayValues(aktuelleBeschaeftigung.Sozialversicherung, '', htmlParentVersicherung, 'ma-');
        // lade Krankenkasse Bezeichnung + Betriebsnr
        displayValues(aktuelleBeschaeftigung.Krankenkasse, '', htmlParentVersicherung, 'ma-Krankenkasse-');
        // lade freiwillige Krankenkasse
        displayValues(aktuelleBeschaeftigung.FreiwilligeGesetzlicheKrankenversicherung, '', htmlParentVersicherung, 'ma-kkfreiwillig-');
        // lade freiwillige Pflegeversicherung
        displayValues(aktuelleBeschaeftigung.FreiwilligeGesetzlichePflegeversicherung, '', htmlParentVersicherung, 'ma-pvfreiwillig-');
        // lade private Krankenversicherung
        displayValues(aktuelleBeschaeftigung.PrivateKrankenkasse, '', htmlParentVersicherung, 'ma-kkprivat-');
        validateAbgaben(aktuellerMA);
    } else {
        await SystemDialogService.instance.displayAsync('kein-ma-alert-dialog');
    }
}

/**
 * Liest die Abgaben des Mitarbeiters aus der Oberfläche und speichert diese per
 * debounceSpeichereMA()
 */
async function speichereAbgaben() {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    if (!_.isEmpty(aktuellerMA)) {
        // TODO: Beschaeftigung auslesen...
        const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
        // Steuerdaten laden...
        const htmlParentSteuer = document.getElementById('ma-Steuer');
        collectValues(aktuelleBeschaeftigung.Steuer, 'ma-', htmlParentSteuer);
        // lade Unfallversicherung Gefahrklasse
        collectValues(aktuelleBeschaeftigung.Berufsgenossenschaft, 'ma-Berufsgenossenschaft-', htmlParentSteuer);
        // lade Sozialversicherungsdaten
        const htmlParentSozial = document.getElementById('ma-Sozialabgaben');
        collectValues(aktuelleBeschaeftigung.Sozialversicherung, 'ma-', htmlParentSozial);

        // lade Krankenkasse Bezeichnung + Betriebsnr. Betriebsnummer is abhängig von der Krankenkasse.
        const htmlParentVersicherung = document.getElementById('ma-Versicherungen');
        collectValues(aktuelleBeschaeftigung.Sozialversicherung, 'ma-', htmlParentVersicherung);
        const krankenkassen = await basedataService.holeKrankenkassenBasedataProvider();
        const auswahlKK = htmlParentVersicherung.querySelector('[aria-label="ma-Krankenkasse-KrankenkasseID"]').value;
        const auswahlKKObject = krankenkassen.find((kk) => kk.Betriebsnummer === auswahlKK);
        const auswahlKKBez = _.isEmpty(auswahlKKObject) ? '' : auswahlKKObject.Kurzbezeichnung;
        const KKBez = htmlParentVersicherung.querySelector('[aria-label="ma-Krankenkasse-Krankenkasse"]'); // input element
        KKBez.value = auswahlKKBez; // value update
        KKBez.title = auswahlKKBez; // title/tooltip update
        const KKBNr = htmlParentVersicherung.querySelector('[aria-label="ma-Krankenkasse-Betriebsnummer"]');
        KKBNr.value = auswahlKK;
        KKBNr.title = auswahlKK;
        collectValues(aktuelleBeschaeftigung.Krankenkasse, 'ma-Krankenkasse-', htmlParentVersicherung);
        aktuelleBeschaeftigung.Krankenkasse.Satz = auswahlKKObject ? auswahlKKObject.Beitragssaetze[0].Allgemein : 0;
        aktuelleBeschaeftigung.Krankenkasse.ZusatzSatz = auswahlKKObject ? auswahlKKObject.Beitragssaetze[0].DZBAN : 0;
        // lade freiwillige Krankenkasse
        collectValues(aktuelleBeschaeftigung.FreiwilligeGesetzlicheKrankenversicherung, 'ma-kkfreiwillig-', htmlParentVersicherung);
        // lade freiwillige Pflegeversicherung
        collectValues(aktuelleBeschaeftigung.FreiwilligeGesetzlichePflegeversicherung, 'ma-pvfreiwillig-', htmlParentVersicherung);
        // lade private Krankenversicherung
        collectValues(aktuelleBeschaeftigung.PrivateKrankenkasse, 'ma-kkprivat-', htmlParentVersicherung);

        window.myVars.mitarbeiterEditiert = true;
        debounceSpeichereMA(aktuellerMA);
    }
}

function validateAbgaben(aktuellerMA) {
    resetValidationMessages();
    const htmlParent = document.getElementById('ma-Abgaben');
    const validateResults = aktuellerMA.Errors;
    validateResults.forEach((error) => {
        const htmlElement = htmlParent.querySelector(`[aria-label="${error.instancePath}"]`);
        if (htmlElement) {
            htmlElement.innerText = error.message;
        }
    });
}

/**
 * Lädt die Lohnarten und Bezuüge des Mitarbeiters und zeigt diese an.
 * Die Auswahl der Lohnarten und Bezüge aus dem Unternehmen werden ebenfalls geladen.
 * @param {object} aktuellerMA
 * @param {object} unternehmen
 */
function ladeMALohnarten(aktuellerMA, unternehmen) {
    if (!_.isEmpty(aktuellerMA)) {
        const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
        const lohnartenU = document.getElementById('u-la-panel');
        displayArray(unternehmen.Lohnarten, 'u-la-', lohnartenU);
        const lohnartenMA = document.getElementById('ma-la-panel');
        displayArray(aktuelleBeschaeftigung.Lohnarten, 'ma-la-', lohnartenMA);
        const bezugU = document.getElementById('u-nba-panel');
        displayArray(unternehmen.NettoBeAbzuege, 'u-nba-', bezugU);
        const bezugMA = document.getElementById('ma-nba-panel');
        displayArray(aktuelleBeschaeftigung.NettoBeAbzuege, 'ma-nba-', bezugMA);
    }
}

/**
 * Verwendet die ausgewählte Lohnart für den Mitarbeiter
 * @param {HTMLElement} thisElement Button der geklickt wurde (enthält die Lohnart ID)
 */
 async function verwendeMALohnart(thisElement) {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
	const lohnartID = thisElement.id;
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const lohnartU = unternehmen.Lohnarten.find((la) => la._id === lohnartID);
	if (!lohnartU) {
		// Lohnart konnte nicht gefunden werden
		return;
	}
    const isInMA = aktuelleBeschaeftigung.Lohnarten.findIndex((la) => la._id === lohnartID);
	if (isInMA >= 0) {
		// der Mitarbeiter besitzt diese Lohnart schon
        systemNachrichtService.zeigeKleineNachricht('Der Mitarbeiter besitzt diese Lohnart bereits!', 0);
		return;
	}
    const lohnartMA = erstelleMALohnart(lohnartU);
    aktuelleBeschaeftigung.Lohnarten.push(lohnartMA);
    aktuelleBeschaeftigung.Lohnarten.sort((a, b) => a.Lohnartennummer - b.Lohnartennummer);
	await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
	// Zeige die neue Lohnart oben an...
	ladeMALohnarten(aktuellerMA, unternehmen);
    await erstelleMitarbeiterListe();
}

function erstelleMALohnart(lohnartUnternehmen) {
    const lohnartMA = _.omit(lohnartUnternehmen, ['Standardbetrag']);
    lohnartMA.Betrag = 0;
    lohnartMA.Menge = 1;
    lohnartMA.Faktor = lohnartUnternehmen.Standardbetrag;
    lohnartMA.Verwenden = true;
    return lohnartMA;
}

/**
 * Soll die ausgewählte Lohnart aus dem Mitarbeiter löschen
 * @param {HTMLElement} thisElement Button der geklickt wurde (enthält die Lohnart ID)
 */
async function entferneMALohnart(thisElement) {
	const prefix = 'ma-la-';
	const lohnartID = thisElement.id;
	const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
	const lohnartIndex = aktuelleBeschaeftigung.Lohnarten.findIndex((la) => la._id === lohnartID);
	if (lohnartIndex < 0) {
		// Lohnart konnte nicht gefunden werden
        systemNachrichtService.zeigeKleineNachricht('Fehler beim Mitarbeiter Speichern!', -1);
		return;
	}
	// Lohnart aus dem Mitarbeiter löschen
	aktuelleBeschaeftigung.Lohnarten.splice(lohnartIndex, 1);
	await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
	document.getElementById(`${prefix}${lohnartID}`).remove();
    await erstelleMitarbeiterListe();
}

/**
 * Speichert die aktuelle Änderung der bearbeiteten Zeile
 * @param {HTMLElement} thisElement input Feld, das bearbeitet wurde
 */
 async function speichereMALohnart(thisElement) {
	// Zeile ist der parent des input elements
	const zeile = thisElement.parentNode;
	// wir brauchen nur die ID, also schließen wir den prefix (ma-la-) aus.
	const zeileID = zeile.id.split('-')[2];
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
    const lohnart = aktuelleBeschaeftigung.Lohnarten.find((la) => la._id === zeileID);
	if (!lohnart) {
		// Lohnart ist nicht im Mitarbeiter findbar...
        systemNachrichtService.zeigeKleineNachricht('Fehler beim Mitarbeiter Speichern!', -1);
		return;
	}
	collectValues(lohnart, 'ma-la-', zeile);
	window.myVars.mitarbeiterEditiert = true;
    debounceSpeichereMA(aktuellerMA);
}

/**
 * Verwendet den ausgewählten NettoBe/Abzug für das Unternehmen
 * @param {HTMLElement} thisElement Button der geklickt wurde (enthält die NettoBe/Abzug ID)
 */
 async function verwendeMABezug(thisElement) {
    const prefix = 'ma-nba-';
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
	const bezugID = thisElement.id;
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const bezugU = unternehmen.NettoBeAbzuege.find((nba) => nba._id === bezugID);
	if (!bezugU) {
		// Lohnart konnte nicht gefunden werden
		return;
	}
    const isInMA = aktuelleBeschaeftigung.NettoBeAbzuege.findIndex((nba) => nba._id === bezugID);
	if (isInMA >= 0) {
		// das unternehmen besitzt diese Lohnart schon
        systemNachrichtService.zeigeKleineNachricht('Der Mitarbeiter besitzt diesen Nettobezug bereits!', 0);
		return;
	}
    const bezugMA = bezugU;
    // Wir übernehmen den Standardbetrag und Verwenden den Bezug initial
    bezugMA.Betrag = bezugU.Standardbetrag;
    bezugMA.Verwenden = true;
    aktuelleBeschaeftigung.NettoBeAbzuege.push(bezugMA);
    aktuelleBeschaeftigung.NettoBeAbzuege.sort((a, b) => a.Lohnartennummer - b.Lohnartennummer);
	await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
	// Zeige die neue Lohnart oben an...
    displayArray(aktuelleBeschaeftigung.NettoBeAbzuege, prefix);
    await erstelleMitarbeiterListe();
}

/**
 * Speichert die aktuelle Änderung der bearbeiteten Zeile
 * @param {HTMLElement} thisElement input Feld, das bearbeitet wurde
 */
 async function speichereMABezug(thisElement) {
	// Zeile ist der parent des input elements
	const zeile = thisElement.parentNode;
	// wir brauchen nur die ID, also schließen wir den prefix (nba-) aus.
	const zeileID = zeile.id.split('-')[2];
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
	const bezug = aktuelleBeschaeftigung.NettoBeAbzuege.find((nba) => nba._id === zeileID);
	if (!bezug) {
		// Bezug ist nicht im Mitarbeiter findbar...
        systemNachrichtService.zeigeKleineNachricht('Fehler beim Mitarbeiter Speichern!', -1);
		return;
	}
	collectValues(bezug, 'ma-nba-', zeile);
	window.myVars.mitarbeiterEditiert = true;
    debounceSpeichereMA(aktuellerMA);
}

/**
 * Soll den ausgewählten Bezug aus dem Mitarbeiter löschen
 * @param {HTMLElement} thisElement Button der geklickt wurde (enthält die Bezug ID)
 */
 async function entferneMABezug(thisElement) {
	const prefix = 'ma-nba-';
	const bezugID = thisElement.id;
	const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
	const bezugIndex = aktuelleBeschaeftigung.NettoBeAbzuege.findIndex((nba) => nba._id === bezugID);
	if (bezugIndex < 0) {
		// Lohnart konnte nicht gefunden werden
        systemNachrichtService.zeigeKleineNachricht('Fehler beim Mitarbeiter Speichern!', -1);
		return;
	}
	// Lohnart aus dem Mitarbeiter löschen
	aktuelleBeschaeftigung.NettoBeAbzuege.splice(bezugIndex, 1);
	await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
	document.getElementById(`${prefix}${bezugID}`).remove();
    await erstelleMitarbeiterListe();
}

/**
 * Erstellt eine neue Zeile bei den Qualifikationen
 * @param {*} thisElement der Button zum Erstellen einer neuen Qualifikation
 * @param {*} prefix ma-qu- Präfix, damit die util Funktionen wissen, wo was zu tun ist.
 */
async function neueMAQualifikation(thisElement, prefix) {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    let aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
    const newRow = addInputRow(thisElement, prefix);
    const qualiSelect = newRow.querySelector('[aria-label="ma-qu-QualifikationID"]');
    fuelleSelectOptionen(qualiSelect, stammdatenService.unternehmensobjekt.Qualifikationen.filter((q) => q.Verwenden), '_id', 'BezeichnungNeutral');
    // Wir fügen eine neue Quali ein...
    const neueQuali = {
        _id: 'updateMe',
        Entlohnung: 'Effektivlohn',
        QualifikationID: stammdatenService.unternehmensobjekt.Qualifikationen[0]._id, // Wir nehmen einfach die erste als Start
        Basisgrundlohn: 0,
        Effektivlohn: 0,
        Verwenden: true,
    };
    newRow.id = prefix + neueQuali._id;
    aktuelleBeschaeftigung.Verguetung.push(neueQuali);
    await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
    aktuelleBeschaeftigung = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0];
    ladeQualifikationen(aktuelleBeschaeftigung);
    validateBeschaeftigung(stammdatenService.aktuellerMA);
    await erstelleMitarbeiterListe();
}

/**
 * Updated die Qualifikation des Mitarbeiters
 * Falls es sich nicht um Effektivlohn handelt, muss Effektivlohn disabled sein.
 * @param {HTMLElement} thisElement element auf das geklickt wurde
 */
async function updateMAQualifikation(thisElement) {
    // Zeile ist der parent des input elements
    let zeile = thisElement.parentNode;
    // Beim Verwenden Input gibt es noch ein Div zwischen der Zeile und dem Input.
    if (!zeile.classList.contains('ma-qu-zeile')) {
        zeile = zeile.parentNode;
    }
    // Effektivlohnfelder aktivieren/zurücksetzen
    const effektivLohnInput = zeile.querySelector('[aria-label="ma-qu-Effektivlohn"]');
    const effektivLohnMonatInput = zeile.querySelector('[aria-label="ma-qu-EffektivlohnMonat"]');
    const entlohnungsartInput = zeile.querySelector('[aria-label="ma-qu-Entlohnung"]');
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
    if (entlohnungsartInput.value !== 'Effektivlohn') {
        effektivLohnInput.disabled = true;
        effektivLohnInput.value = 0;
        effektivLohnMonatInput.value = 0;
    } else {
        effektivLohnInput.disabled = false;
        updateEffektivlohn(aktuelleBeschaeftigung.SollstundenMonat);
    }
    // Beteiligung bei Entlohnungswechsel aktivieren/zurücksetzen
    const beteiligung = zeile.querySelector('[aria-label="ma-qu-UmsatzBeteiligungProzent"');
    if (!['Umsatzanteilslohn', 'umsatzorientierterStundenlohn'].includes(entlohnungsartInput.value)) {
        beteiligung.disabled = true;
        beteiligung.value = 0;
    } else {
        beteiligung.disabled = false;
    }
	// wir brauchen nur die ID, also schließen wir den prefix (qu-) aus.
	const zeileID = zeile.id.split('-')[2];
    // Falls die ZeileID zu einer VerguetungID passt, können wir einfach die bestehenden Daten updaten.
    const quali = aktuelleBeschaeftigung.Verguetung.find((vg) => vg._id === zeileID);
    if (!quali) {
        // Bezug ist nicht im Mitarbeiter findbar...
        systemNachrichtService.zeigeKleineNachricht('Fehler beim Mitarbeiter Speichern!', -1);
        return;
    }
    collectValues(quali, 'ma-qu-', zeile);
    if (thisElement.type === 'checkbox') {
        ladeQualifikationen(aktuelleBeschaeftigung);
    }
	window.myVars.mitarbeiterEditiert = true;
    debounceSpeichereMA(aktuellerMA);
}

/**
 * Löscht die ausgewählte Qualifikation aus dem Mitarbeiter.
 * @param {HTMLElement} thisElement Entfernenbutton
 */
async function deleteMAQualifikation(thisElement) {
    const qualiID = thisElement.id;
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
	const qualiIndex = aktuelleBeschaeftigung.Verguetung.findIndex((qu) => qu._id === qualiID);
	if (qualiIndex < 0) {
		// Qualifikation konnte nicht gefunden werden
        systemNachrichtService.zeigeKleineNachricht('Fehler beim Löschen der Qualifikation!', -1);
		return;
	}
	// Qualifikation aus dem Mitarbeiter löschen
	aktuelleBeschaeftigung.Verguetung.splice(qualiIndex, 1);
	await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
    validateBeschaeftigung(stammdatenService.aktuellerMitarbeiter);
    ladeQualifikationen(aktuelleBeschaeftigung);
    await erstelleMitarbeiterListe();
}

async function updateSollstundenMonat(thisElement) {
    const aktuelleBeschaeftigung = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0];
    const sollstundenMonat = parseFloat(thisElement.value);
    updateEffektivlohn(sollstundenMonat);
    const sollstundenWoche = Math.round((sollstundenMonat / 4.35) * 100) / 100;
    document.querySelector('[aria-label="ma-SollstundenWoche"]').value = sollstundenWoche;
    if (aktuelleBeschaeftigung.SolltageWoche > 0) {
        const sollstundenTag = Math.round((sollstundenWoche / aktuelleBeschaeftigung.SolltageWoche) * 100) / 100;
        document.querySelector('[aria-label="ma-SollstundenTag"]').value = sollstundenTag;
    }
    await speichereBeschaeftigung();
}

async function updateSollstundenWoche(thisElement) {
    const aktuelleBeschaeftigung = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0];
    const sollstundenWoche = parseFloat(thisElement.value);
    const sollstundenMonat = Math.round((sollstundenWoche * 4.35) * 100) / 100;
    document.querySelector('[aria-label="ma-SollstundenMonat"]').value = sollstundenMonat;
    updateEffektivlohn(sollstundenMonat);
    document.querySelector('[aria-label="ma-SollstundenWoche"]').value = sollstundenWoche;
    if (aktuelleBeschaeftigung.SolltageWoche > 0) {
        const sollstundenTag = Math.round((sollstundenWoche / aktuelleBeschaeftigung.SolltageWoche) * 100) / 100;
        document.querySelector('[aria-label="ma-SollstundenTag"]').value = sollstundenTag;
    }
    await speichereBeschaeftigung();
}

async function updateSollstundenTag(thisElement) {
    const aktuelleBeschaeftigung = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0];
    const sollstundenTag = parseFloat(thisElement.value);
    if (aktuelleBeschaeftigung.SolltageWoche > 0) {
        const sollstundenWoche = Math.round((sollstundenTag * aktuelleBeschaeftigung.SolltageWoche) * 100) / 100;
        const sollstundenMonat = Math.round((sollstundenWoche * 4.35) * 100) / 100;
        document.querySelector('[aria-label="ma-SollstundenWoche"]').value = sollstundenWoche;
        document.querySelector('[aria-label="ma-SollstundenMonat"]').value = sollstundenMonat;
        updateEffektivlohn(sollstundenMonat);
    }
    await speichereBeschaeftigung();
}

async function updateSolltageWoche(thisElement) {
    const aktuelleBeschaeftigung = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0];
    const solltageWoche = parseFloat(thisElement.value);
    if (aktuelleBeschaeftigung.SollstundenWoche) {
        const sollstundenWoche = aktuelleBeschaeftigung.SollstundenWoche;
        const sollstundenMonat = Math.round((sollstundenWoche * 4.35) * 100) / 100;
        const sollstundenTag = Math.round((sollstundenWoche / solltageWoche) * 100) / 100;
        document.querySelector('[aria-label="ma-SollstundenTag"]').value = sollstundenTag;
        document.querySelector('[aria-label="ma-SollstundenMonat"]').value = sollstundenMonat;
        updateEffektivlohn(sollstundenMonat);
    }
    await speichereBeschaeftigung();
}

function updateEffektivlohn(sollstundenMonat) {
    document.querySelectorAll('[aria-label="ma-qu-EffektivlohnMonat"]').forEach((input) => { input.value = 0; });

    const qualiChecks = Array.from(document.querySelectorAll('[aria-label="ma-qu-Verwenden"]')).filter((checkbox) => checkbox.checked);
    // Wir berechnen nur falls es genau eine aktive Qualifikation gibt.
    if (qualiChecks.length === 1) {
        const zeile = qualiChecks[0].closest('.ma-qu-zeile');
        const entlohnungsartInput = zeile.querySelector('[aria-label="ma-qu-Entlohnung"]');
        if (entlohnungsartInput.value === 'Effektivlohn') {
            const effektivLohnInput = zeile.querySelector('[aria-label="ma-qu-Effektivlohn"]');
            const effektivLohnMonatInput = zeile.querySelector('[aria-label="ma-qu-EffektivlohnMonat"]');
            effektivLohnMonatInput.value = (effektivLohnInput.value * sollstundenMonat).toFixed(2);
        }
    }
}

/**
 * Speichert das aktuelle Mitarbeiternotizenfeld
 * @param {*} thisElement Notiz textarea
 */
function speichereMitarbeiterNotiz(thisElement) {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    aktuellerMA.Uebersicht.Notizen = thisElement.value;
    window.myVars.mitarbeiterEditiert = true;
    debounceSpeichereMA(aktuellerMA);
}

/**
 * Speichert die ausgewählte Beschäftigungsart fügt PGS, BGS, und Sollstunden/-tage ein.
 * @param {HTMLElement} thisElement Select Dropdown der Beschäftigungsarten
 */
async function speichereBeschaeftigungsArt(thisElement) {
    const beschaeftigungID = thisElement.value;
    const beschaeftigungen = await basedataService.holeBeschaeftigungsartenBasedataProvider();
    const beschaeftigung = beschaeftigungen.find((b) => b.BeschID === beschaeftigungID);
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
    aktuelleBeschaeftigung.Beschaeftigungsart = beschaeftigung.BeschID;
    aktuelleBeschaeftigung.Personengruppenschluessel = beschaeftigung.Personengruppenschluessel;
    aktuelleBeschaeftigung.SollstundenTag = beschaeftigung.SollstundenTag;
    aktuelleBeschaeftigung.SollstundenWoche = beschaeftigung.SollstundenWoche;
    aktuelleBeschaeftigung.SollstundenMonat = beschaeftigung.SollstundenMonat;
    aktuelleBeschaeftigung.SolltageWoche = beschaeftigung.SolltageWoche;
    aktuelleBeschaeftigung.Steuer.Steuerberechnung = beschaeftigung.Steuerberechnung;
    aktuelleBeschaeftigung.Sozialversicherung.BeitragsgruppenschluesselKrankenversicherung = beschaeftigung.BGSKV;
    aktuelleBeschaeftigung.Sozialversicherung.BeitragsgruppenschluesselArbeitslosenversicherung = beschaeftigung.BGSAV;
    aktuelleBeschaeftigung.Sozialversicherung.BeitragsgruppenschluesselRentenversicherung = beschaeftigung.BGSRV;
    aktuelleBeschaeftigung.Sozialversicherung.BeitragsgruppenschluesselPflegeversicherung = beschaeftigung.BGSPV;
    berechneUrlaubsanspruch(aktuelleBeschaeftigung);
    await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
    await ladeBeschaeftigung(stammdatenService.aktuellerMitarbeiter, stammdatenService.unternehmensobjekt);
    await erstelleMitarbeiterListe();
}

/**
 * Aktualisiert die Werte des Urlaubsanspruchs und Summen.
 * @param {object} aktuelleBeschaeftigung
 */
function berechneUrlaubsanspruch(aktuelleBeschaeftigung) {
    aktuelleBeschaeftigung.Urlaub.UrlaubsanspruchProJahr = 4 * aktuelleBeschaeftigung.SolltageWoche;
    const urlaubsmonateGesetzlich = berechneUrlaubsmonate(aktuelleBeschaeftigung, true);
    const urlaubsmonateZusatz = berechneUrlaubsmonate(aktuelleBeschaeftigung, false);
    aktuelleBeschaeftigung.Urlaub.UrlaubsanspruchLaufendesJahr = berechneUrlaubsanspruchGesetz(aktuelleBeschaeftigung, urlaubsmonateGesetzlich);
    aktuelleBeschaeftigung.Urlaub.UrlaubsanspruchZusatzLaufendesJahr = berechneUrlaubsanspruchZusatz(aktuelleBeschaeftigung, urlaubsmonateZusatz);
    // Die Summe des Urlaubsanspruch sowie die verbleibenden Urlaubstage werden davon betroffen und müssen angepasst werden...
    aktuelleBeschaeftigung.Urlaub.UrlaubsanspruchSumme = aktuelleBeschaeftigung.Urlaub.UrlaubsanspruchZusatzLaufendesJahr + aktuelleBeschaeftigung.Urlaub.UrlaubsanspruchLaufendesJahr + aktuelleBeschaeftigung.Urlaub.ResturlaubVorwert;
    aktuelleBeschaeftigung.Urlaub.ResturlaubLaufendesJahr = aktuelleBeschaeftigung.Urlaub.UrlaubsanspruchSumme - aktuelleBeschaeftigung.Urlaub.UrlaubGenehmigtSumme;
    stammdatenService.aktuellerMitarbeiter.Uebersicht.Resturlaub = aktuelleBeschaeftigung.Urlaub.ResturlaubLaufendesJahr;
}

/**
 * Berechnet den gesetzlichen Urlaubsanspruch für das laufende Jahr (ausgehend, dass das aktuellesMonatsDatum im richtigem Jahr ist)
 * @param {object} aktuelleBeschaeftigung
 * @returns urlaubsanspruchLaufend
 */
function berechneUrlaubsanspruchGesetz(aktuelleBeschaeftigung, urlaubsmonate) {
    const mindesturlaub = aktuelleBeschaeftigung.SolltageWoche * 4;
    const urlaubProMonat = mindesturlaub / 12;
    const urlaubsanspruch = Math.ceil(urlaubsmonate * urlaubProMonat);
    return urlaubsanspruch;
}

/**
 * Berechnet den zusätzlichen Urlaubsanspruch für das laufende Jahr (ausgehend, dass das aktuellesMonatsDatum im richtigem Jahr ist)
 * @param {object} aktuelleBeschaeftigung
 * @returns urlaubsanspruchZusatzLaufend
 */
function berechneUrlaubsanspruchZusatz(aktuelleBeschaeftigung, urlaubsmonate) {
    const zusatzProJahr = aktuelleBeschaeftigung.Urlaub.UrlaubsanspruchZusatzProJahr;
    const zusatzProMonat = zusatzProJahr / 12;
    const zusatzsanspruch = Math.ceil(urlaubsmonate * zusatzProMonat);
    return zusatzsanspruch;
}

/**
 * Berechnet anteilig von dem Eintritt und Austrittsdatum die Anzahl an urlaubsrelevanten Monaten aus.
 * - Eintrittsdatum ist immer relevant
 * - Austrittsdatum nur bis zum 30.06. relevant.
 * @param {object} aktuelleBeschaeftigung
 * @returns anzahl an urlaubsmonaten
 */
function berechneUrlaubsmonate(aktuelleBeschaeftigung, isGesetzlich = false) {
    const eintrittsdatum = aktuelleBeschaeftigung.Eintrittsdatum;
    const austrittsdatum = aktuelleBeschaeftigung.Austrittsdatum;
    // Wir nehmen das Jahr im Urlaubsobjekt...
    const urlaubsJahrDayjs = dayjs({ year: aktuelleBeschaeftigung.Urlaub.UrlaubJahr });
    const eintrittDayjs = (eintrittsdatum === null || eintrittsdatum === '') ? urlaubsJahrDayjs.startOf('year') : dayjs(eintrittsdatum);
    const austrittDayjs = (austrittsdatum === null || austrittsdatum === '') ? urlaubsJahrDayjs.endOf('year') : dayjs(austrittsdatum);
    let urlaubsmonate = 12;
    if (isGesetzlich // Austrittsdatum ist relevant, falls es vor dem 01.07. eines Jahres ist (im gesetzlichen Urlaub)
        && eintrittDayjs.isSame(urlaubsJahrDayjs, 'year')
        && austrittDayjs.isSame(urlaubsJahrDayjs, 'year')
        && austrittDayjs.isBefore(dayjs(urlaubsJahrDayjs.month(6).date(1), 'month'))) {
        // relevante anzahl an monate = monate zwischen eintritt und austritt + 1, da das Austrittsdatum normalerweise am Ende das Monats ist.
        urlaubsmonate = austrittDayjs.month() - eintrittDayjs.month() + 1;
    } else if (isGesetzlich // Austrittsdatum ist auch relevant falls die Beschäftigung kürzer als 6 Monate dauert und nach dem 30.06. endet.
        && eintrittDayjs.add(5, 'months').endOf('month').isAfter(austrittDayjs, 'day')
        && austrittDayjs.isAfter(dayjs(urlaubsJahrDayjs.month(5).date(30), 'day'))) {
        // relevante anzahl an monate = monate zwischen eintritt und austritt + 1 (falls am letzten Tag des Monats), da das Austrittsdatum normalerweise am Ende das Monats ist.
        urlaubsmonate = austrittDayjs.diff(eintrittDayjs, 'month') + 1;
    } else if (!isGesetzlich // Austrittsdatum wird immer anteilig berechnet für den zusätzlichen Urlaubsanspruch
        && eintrittDayjs.isSame(urlaubsJahrDayjs, 'year')
        && austrittDayjs.isSame(urlaubsJahrDayjs, 'year')) {
        // relevante anzahl an monate = monate zwischen eintritt und austritt + 1, da das Austrittsdatum normalerweise am Ende das Monats ist.
        urlaubsmonate = austrittDayjs.month() - eintrittDayjs.month() + 1;
    } else if (isGesetzlich // Falls es nur ein Eintrittsdatum gibt, werden die Monate bis zum Eintrittsdatum abgezogen.
        && eintrittDayjs.isSame(urlaubsJahrDayjs, 'year')
        && austrittDayjs.isSameOrAfter(urlaubsJahrDayjs.endOf('year'), 'year')) {
        urlaubsmonate -= eintrittDayjs.month();
    } else if (isGesetzlich // Falls es nur ein AustrittsDatum gibt, werden die Monate nach dem Austrittsdatum (gesetzlich nur bis 30.06.) abgezogen (der Austrittsmonat zählt aber noch).
        && !eintrittDayjs.isSame(urlaubsJahrDayjs, 'year')
        && austrittDayjs.isSame(urlaubsJahrDayjs, 'year')
        && austrittDayjs.isBefore(dayjs(urlaubsJahrDayjs.month(6).date(1), 'month'))) {
        urlaubsmonate -= (12 - austrittDayjs.month() - 1);
    } else if (!isGesetzlich // Falls es nur ein AustrittsDatum gibt, werden die Monate nach dem Austrittsdatum abgezogen (der Austrittsmonat zählt aber noch).
        && !eintrittDayjs.isSame(urlaubsJahrDayjs, 'year')
        && austrittDayjs.isSame(urlaubsJahrDayjs, 'year')) {
        urlaubsmonate -= (12 - austrittDayjs.month() - 1);
    }
    // niemals negativen Urlaubsanspruch zurückgeben.
    return _.max([urlaubsmonate, 0]);
}

async function ladeHistorie(aktuellerMA) {
    if (!_.isEmpty(aktuellerMA)) {
        const historie = await stammdatenService.leseJournalHistorieMitarbeiter(aktuellerMA._id);
        const parentHTML = document.getElementById('ma-jh-panel');
        displayArray(historie, 'ma-jh-', parentHTML);
    }
}

async function neueMitarbeiterNachricht(thisElement) {
    const parentElement = thisElement.closest('.fieldset3columns');
    const nachricht = parentElement.querySelector('#ma-nachricht-text');
    if (nachricht.value === '') {
        systemNachrichtService.zeigeKleineNachricht('kein Nachrichttext angebeben!');
        return;
    }
    // server request...
    const mitarbeiterID = stammdatenService.aktuellerMitarbeiter._id;
    const nachrichtObjekt = await stammdatenService.postMitarbeiterNachricht(mitarbeiterID, nachricht.value);
    if (!nachrichtObjekt) {
        return;
    }
    const maControl = document.getElementById(mitarbeiterID);
    // Zeige das Brief Symbol beim Mitarbeiter an
    maControl.querySelector('.ma-nachricht-icon').classList.remove('hidden');
    const template = document.querySelector('[ma-nachricht-template]');
    const liste = document.getElementById('ma-nachrichten-liste');
    const neueNachricht = template.content.cloneNode(true).children[0];
    neueNachricht.id = nachrichtObjekt._id;
    neueNachricht.querySelector('[aria-label="ma-Nachrichten-Nachricht"]').value = nachrichtObjekt.Nachricht;
    neueNachricht.querySelector('[aria-label="ma-Nachrichten-ErstelltVon"]').innerText = nachrichtObjekt.ErstelltVon;
    neueNachricht.querySelector('[aria-label="ma-Nachrichten-ErstelltDatum"]').innerText = dayjs(nachrichtObjekt.ErstelltDatum).format('DD.MM.YY');
    liste.prepend(neueNachricht);
    nachricht.value = '';
}

/**
 * Speichert die aktualisierte Mitarbeiter Nachricht
 * @param {HTMLElement} thisElement
 */
async function speichereMitarbeiterNachricht(thisElement) {
    const parentElement = thisElement.closest('.fieldset3columns');
    const nachrichtId = parentElement.id;
    const nachrichtNeu = parentElement.querySelector('[aria-label="ma-Nachrichten-Nachricht"]').value;
    const erledigt = parentElement.querySelector('[aria-label="ma-Nachrichten-Erledigt"]').checked;
    const mitarbeiterID = stammdatenService.aktuellerMitarbeiter._id;
    const nachrichtObjekt = await stammdatenService.updateMitarbeiterNachricht(mitarbeiterID, nachrichtId, nachrichtNeu, erledigt);
    parentElement.querySelector('[aria-label="ma-Nachrichten-BearbeitetVon"]').innerText = nachrichtObjekt.BearbeitetVon;
    parentElement.querySelector('[aria-label="ma-Nachrichten-BearbeitetDatum"]').innerText = dayjs(nachrichtObjekt.BearbeitetDatum).format('DD.MM.YY');
    parentElement.querySelector('.ma-notizen-bearbeitet-info').style.display = 'block';
    // Zeige das Brief Symbol beim Mitarbeiter an falls auf unerledigt gesetzt und ausblenden wenn erledigt
    const maControl = document.getElementById(mitarbeiterID);
    const briefSymbolMA = maControl.querySelector('.ma-nachricht-icon');
    if (!stammdatenService.aktuellerMitarbeiter.Uebersicht.NeueNachricht) {
        briefSymbolMA.classList.add('hidden');
        return;
    }
    briefSymbolMA.classList.remove('hidden');
}

/**
 * Lädt die Nachrichten des Mitarbeiters in die Oberfläche
 * @param {array} nachrichten
 */
function ladeMitarbeiterNachrichten(nachrichten) {
    const template = document.querySelector('[ma-nachricht-template]');
    const liste = document.getElementById('ma-nachrichten-liste');
    liste.innerHTML = '';
    nachrichten.sort((a, b) => new Date(b.BearbeitetDatum || b.ErstelltDatum) - new Date(a.BearbeitetDatum || a.ErstelltDatum));
    nachrichten.sort((a, b) => a.Erledigt - b.Erledigt);
    nachrichten.forEach((nachricht) => {
        const neueNachricht = template.content.cloneNode(true).children[0];
        neueNachricht.id = nachricht._id;
        displayValues(nachricht, '', neueNachricht, 'ma-Nachrichten-');
        if (nachricht.BearbeitetVon) {
            neueNachricht.querySelector('.ma-notizen-bearbeitet-info').style.display = 'block';
        }
        liste.appendChild(neueNachricht);
    });
}

// fetcht den Aktivierungscode des mitarbeiters falls vorhanden
async function ladeAktivierungsCode(mitarbeiterID) {
    try {
        const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiterID}/aktivierungscode`);
        const span = document.getElementById('ma-activation-code-number');
        const btn = document.getElementById('ma-activation-code-button');
        // Reset der Info
        span.innerText = '';
        span.style.display = 'none';
        btn.style.display = 'none';
        if (response.ok) {
            span.innerText = await response.text();
            span.style.display = 'block';
        }
        if (response.status === 404) {
            btn.style.display = 'block';
        }
    } catch (error) {
        console.log(error);
    }
}

// fetch anfrage für die Erstellung eines Aktivierungscode für die Zeiterfassung
async function generiereAktivierungsCode() {
    try {
        const mitarbeiterID = stammdatenService.aktuellerMitarbeiter._id;
        const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiterID}/aktivierungscode`, {
            method: 'POST',
        });
        if (response.ok) {
            // Reset der Info
            const span = document.getElementById('ma-activation-code-number');
            const btn = document.getElementById('ma-activation-code-button');
            span.innerText = '';
            span.style.display = 'none';
            btn.style.display = 'none';
            span.innerText = await response.text();
            span.style.display = 'block';
            systemNachrichtService.zeigeKleineNachricht('Erstellen des Aktivierungscodes erfolgreich!', 1);
        }
    } catch (error) {
        systemNachrichtService.zeigeKleineNachricht('Erstellen des Aktivierungscodes fehlgeschlagen!', -1);
    }
}

/**
 * Click Handler für die Erstellung einer Mitarbeiter Sofortmeldung
 */
async function beantrageSofortmeldung() {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const aktuelleBetriebsstaette = stammdatenService.unternehmensobjekt.Betriebsstaette.find((bs) => bs._id === stammdatenService.aktuelleBetriebsstaette);
    if (_.isEmpty(aktuelleBetriebsstaette)) {
        return;
    }
    const dialogHtml = document.getElementById('ma-sofortmeldung-beantragen-dialog');
    const dialogBody = dialogHtml.querySelector('.app-dialog-body');
    dialogBody.innerHTML = '';
    const templateBody = dialogHtml.querySelector('[ma-sm-body-template]');
    const clone = templateBody.content.cloneNode(true);
    displayValues(aktuellerMA, '', clone, 'ma-sm-dialog-');
    displayValues(aktuellerMA.Personalien.Staatsangehoerigkeit, '', clone, 'ma-sm-dialog-Staatsangehoerigkeit-');
    displayValues(aktuellerMA.Personalien.Geburtsland, '', clone, 'ma-sm-dialog-Geburtsland-');
    displayValues(aktuelleBetriebsstaette, '', clone, 'bs-sm-dialog-');
    dialogBody.appendChild(clone);
    const input = await SystemDialogService.instance.displayAsync('ma-sofortmeldung-beantragen-dialog');
    if (input.success) {
        const result = await stammdatenService.postSofortmeldungMitarbeiter(aktuellerMA._id, aktuelleBetriebsstaette._id);
        if (result?.status === 'success') {
            ladeSofortmeldung(stammdatenService.aktuellerMitarbeiter);
        } else {
            // Fehler anzeigen
            const body = document.getElementById('ma-sm-error-body');
            const boldText = document.createElement('b');
            boldText.innerText = `Mitarbeiter: ${aktuellerMA.Personalien.Nachname}, ${aktuellerMA.Personalien.Vorname}`;
            body.appendChild(boldText);
            result.forEach((error) => {
                if (error.keyword === 'if') {
                    return;
                }
                const newRow = document.createElement('p');
                newRow.innerText = `${error.message}`;
                body.appendChild(newRow);
            });
            await SystemDialogService.instance.displayAsync('ma-sofortmeldung-error-dialog');
            body.innerText = '';
        }
    }
    dialogBody.innerHTML = '';
}

/**
 * Click Handler für die Stornierung einer Mitarbeiter Sofortmeldung
 */
async function storniereSofortmeldung() {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const dialogHtml = document.getElementById('ma-sofortmeldung-stornieren-dialog');
    const dialogName = dialogHtml.querySelector('#ma-sm-storno-name');
    dialogName.innerText = `${aktuellerMA.Personalien.Vorname} ${aktuellerMA.Personalien.Nachname}`;
    const input = await SystemDialogService.instance.displayAsync('ma-sofortmeldung-stornieren-dialog');
    if (input.success) {
        // führe storno aus
        const result = await stammdatenService.postStornierungSofortmeldungMitarbeiter(aktuellerMA._id);
        if (result?.status === 'success') {
            ladeSofortmeldung(stammdatenService.aktuellerMitarbeiter);
        }
    }
    dialogName.innerText = '';
}

/**
 * Forciert Großbuchstaben und Leerzeichen nach jeder 4. Ziffer für die IBAN
 * @param {HTMLElement} thisElement IBAN Input Feld
 */
async function inputIBAN(thisElement) {
    thisElement.value = thisElement.value.toUpperCase();
    thisElement.value = thisElement.value.replaceAll(' ', '').match(/.{1,4}/g).join(' ');
    await speicherePersonalien();
}

/**
 * Forciert Großbuchstaben für die SV Nummer
 * @param {HTMLElement} thisElement SVNr Input Feld
 */
async function inputRVNr(thisElement) {
    thisElement.value = thisElement.value.toUpperCase();
    await speichereAbgaben();
}

/**
 * Event Handler für Klick auf Mitarbeiter Kopieren Button in der ma_uebersicht.html
 */
async function wechsleBeschaeftigungMA() {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    // Dialog bestücken...
    const dialogHtml = document.getElementById('ma-beschaeftigung-wechsel-dialog');
    dialogHtml.querySelector('#mb-wechsel-austritt').value = aktuellerMA.Beschaeftigung[0].Austrittsdatum;
    dialogHtml.querySelector('#mb-wechsel-personalnummer').value = aktuellerMA.Personalien.Personalnummer;
    const selectBeschaeftigung = dialogHtml.querySelector('#mb-wechsel-beschaeftigungsart');
    const beschaeftigungen = await basedataService.holeBeschaeftigungsartenBasedataProvider();
    fuelleSelectOptionen(selectBeschaeftigung, beschaeftigungen, 'BeschID', ['BeschaeftigungsartKurz', 'Personengruppenschluessel']);
    selectBeschaeftigung.value = aktuellerMA.Beschaeftigung[0].Beschaeftigungsart;
    const input = await SystemDialogService.instance.displayAsync('ma-beschaeftigung-wechsel-dialog');
    if (!input.success) {
        return;
    }
    const neuerMA = await stammdatenService.kopiereMitarbeiterBeschaeftigungswechsel(aktuellerMA._id, input.data);
    // neuen mitarbeiter urlaubsbrechnung und mit update validieren
    berechneUrlaubsanspruch(neuerMA.Beschaeftigung[0]);
    await stammdatenService.updateMitarbeiterdaten(neuerMA);
    // liste und mitarbeiter neu laden...
    await stammdatenService.holeMitarbeiter(aktuellerMA._id);
    ladeUebersicht(stammdatenService.aktuellerMitarbeiter, stammdatenService.unternehmensobjekt);
	await erstelleMitarbeiterListe(stammdatenService.aktuelleBetriebsstaette);
}

// Dialog confirm handler
function confirmWechselBeschaeftigungMA() {
    const dialogHtml = document.getElementById('ma-beschaeftigung-wechsel-dialog');
    const austritt = dialogHtml.querySelector('#mb-wechsel-austritt').value;
    const eintritt = dialogHtml.querySelector('#mb-wechsel-eintritt').value;
    // nicht ohne richte Datumsangaben abschicken lassen
    if (dayjs(austritt).isAfter(eintritt) || austritt === '' || eintritt === '') {
        systemNachrichtService.zeigeKleineNachricht('Eintrittsdatum muss nach dem Austrittsdatum liegen!');
        return;
    }
    SystemDialogService.instance.confirm({
		AustrittsdatumAlt: austritt,
		EintrittsdatumNeu: eintritt,
		BeschaeftigungsartNeu: dialogHtml.querySelector('#mb-wechsel-beschaeftigungsart').value,
		PersonalnummerNeu: dialogHtml.querySelector('#mb-wechsel-personalnummer').value
	});
}

export {
    ladeAbgaben,
    ladeBeschaeftigung,
    ladeHistorie,
    ladeMALohnarten,
    ladePersonalien,
    ladeUebersicht,
    ladeZuschlaege,
    forceUpdateMA,
    debounceSpeichereMA,
    validateZeitkonto,
    berechneUrlaubsmonate
};
