import _ from 'underscore';

import dayjs from '../../../shared/services/dayjs.js';
import basedataService from './services/basedataService.js';
import stammdatenService from './services/stammdatenService.js';
import SystemDialogService from '../../../shared/services/systemDialogService.js';
import systemNachrichtService from './services/systemNachrichtService.js';
import {
    collectValues,
    debounce,
    displayArray,
    displayValues,
    erstelleOptionGroup,
    fuelleBetriebSelect,
    fuelleSelectOptionen,
    neueArrayRow,
    resetDialogInput,
    setMenuActive,
	toggleLoader,
} from './util.js';

const updateDebounceUnternehmen = debounce(async (args) => {
    // call the function you want to debounce
	await stammdatenService.updateUnternehmensdaten(args[0]);
});

const debounceSpeichereVorlage = debounce(async (args) => {
	await speichereVorlage(args[0]);
}, 1000);

// Verwaltung Event Handlers
window.myHandlers = window.myHandlers || {};
window.myHandlers.confirmNeueBetriebsstaetteDialog = confirmNeueBetriebsstaetteDialog;
window.myHandlers.confirmNeueQualifikationDialog = confirmNeueQualifikationDialog;
window.myHandlers.confirmNeuerBezugDialog = confirmNeuerBezugDialog;
window.myHandlers.confirmNeuerEinsatzortDialog = confirmNeuerEinsatzortDialog;
window.myHandlers.confirmToggleBetriebDialog = confirmToggleBetriebDialog;
window.myHandlers.confirmVorlageNeuDialog = confirmVorlageNeuDialog;
window.myHandlers.confirmNeuerBereichDialog = confirmNeuerBereichDialog;
window.myHandlers.confirmNeuerPostenDialog = confirmNeuerPostenDialog;
window.myHandlers.neuerBezug = neuerBezug;
window.myHandlers.neueQualifikation = neueQualifikation;
window.myHandlers.neuerEinsatzort = neuerEinsatzort;
window.myHandlers.neueBetriebsstaette = neueBetriebsstaette;
window.myHandlers.speichereBetriebsstaette = speichereBetriebsstaette;
window.myHandlers.neuerBereich = neuerBereich;
window.myHandlers.neuerPosten = neuerPosten;
window.myHandlers.speichereBezug = speichereBezug;
window.myHandlers.speichereEinsatzort = speichereEinsatzort;
window.myHandlers.speichereUnternehmensdaten = speichereUnternehmensdaten;
window.myHandlers.speichereLohnart = speichereLohnart;
window.myHandlers.speichereQuali = speichereQuali;
window.myHandlers.speichereVorlage = speichereVorlage;
window.myHandlers.debounceSpeichereVorlage = debounceSpeichereVorlage;
window.myHandlers.verwendeLohnart = verwendeLohnart;
window.myHandlers.entferneLohnart = entferneLohnart;
window.myHandlers.entferneBezug = entferneBezug;
window.myHandlers.neueVorlage = neueVorlage;
window.myHandlers.toggleBetriebsstaette = toggleBetriebsstaette;
window.myHandlers.editDocName = editDocName;
window.myHandlers.confirmAddUserRole = confirmAddUserRole;
window.myHandlers.confirmDelUserRole = confirmDelUserRole;
window.myHandlers.confirmNewUserRoleMA = confirmNewUserRoleMA;
window.myHandlers.benutzerAddRolleClick = benutzerAddRolleClick;
window.myHandlers.benutzerDelRolleClick = benutzerDelRolleClick;
window.myHandlers.benutzerNeueRolleClickMA = benutzerNeueRolleClickMA;
window.myHandlers.toggleBenutzerAktiv = toggleBenutzerAktiv;
window.myHandlers.erstelleExternenBenutzer = erstelleExternenBenutzer;
window.myHandlers.confirmNewExternalUser = confirmNewExternalUser;
window.myHandlers.setzeVorlageNeuName = setzeVorlageNeuName;
window.myHandlers.selectPausenmodell = selectPausenmodell;
window.myHandlers.pausenmodellAddPause = pausenmodellAddPause;
window.myHandlers.pausenmodellRemovePause = pausenmodellRemovePause;
window.myHandlers.speicherePausenmodell = speicherePausenmodell;
window.myHandlers.erstellePausenmodell = erstellePausenmodell;
window.myHandlers.speichereAppEinstellungen = speichereAppEinstellungen;
window.myHandlers.speichereCronjobEinstellungen = speichereCronjobEinstellungen;
window.myHandlers.clickNeuesVerzeichnis = clickNeuesVerzeichnis;
window.myHandlers.confirmNeuesVerzeichnisDialog = confirmNeuesVerzeichnisDialog;
window.myHandlers.speicherePostfachVerzeichnisse = speicherePostfachVerzeichnisse;
window.myHandlers.clickEntferneVerzeichnis = clickEntferneVerzeichnis;

// Befüllt die Oberfläche der Verwaltung der Stammdaten
async function ladeVerwaltung() {
    setMenuActive(document.getElementById('Stammdaten'), 'submenu_active');
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const panelUnternehmen = document.getElementById('u-input-panel');
	const unfallV = await basedataService.holeUVBasedataProvider();
	const bundeslaender = await basedataService.holeBundeslaenderBasedataProvider();
	const bundeslandSelect = panelUnternehmen.querySelector('[aria-label="u-input-Bundesland"');
	fuelleSelectOptionen(bundeslandSelect, bundeslaender, 'Bundesland', 'Bundesland');
    displayValues(_.omit(unternehmen, 'Betriebsstaette'), '', document.getElementById('u-input-panel'), 'u-input-');
    displayArray(unternehmen.Bereiche, 'u-input-bereiche-', panelUnternehmen);
	// Lade alle Betriebsstätten sortiert nach Aktiv/Inkaktiv
	unternehmen.Betriebsstaette.sort((a, b) => Number(b.Aktiv) - Number(a.Aktiv));
    unternehmen.Betriebsstaette.forEach((bs) => {
		const template = document.querySelector('[bs-panel-template]');
        const newPanel = template.content.cloneNode(true).children[0];
		newPanel.id = `${bs._id}-panel`;
        newPanel.querySelector('[bs-button-header]').innerText = `Betriebsstätte: ${bs.BetriebsstaetteName}`;
		const toggleButton = newPanel.querySelector('[aria-label="bs-input-toggle"]');
		toggleButton.id = bs._id;
		if (bs.Aktiv) {
			toggleButton.classList.add('entfernen-button');
			toggleButton.innerText = 'Betriebsstätte deaktivieren';
		} else {
			newPanel.querySelector('[bs-button-header]').innerText += ' (inaktiv)';
			toggleButton.classList.add('verwenden-button');
			toggleButton.innerText = 'Betriebsstätte reaktivieren';
		}
		const unfallSelect = newPanel.querySelector('[aria-label="bs-input-GesetzlicheUV"');
		fuelleSelectOptionen(unfallSelect, unfallV, 'BNR-UV', 'NAME');
		const bundeslandSelectBS = newPanel.querySelector('[aria-label="bs-input-Bundesland"');
		fuelleSelectOptionen(bundeslandSelectBS, bundeslaender, 'Bundesland', 'Bundesland');
        displayValues(bs, '', newPanel, 'bs-input-');
        displayArray(bs.Posten, 'bs-input-posten-', newPanel);
        document.getElementById('bs-create-button').insertAdjacentElement('beforebegin', newPanel);
    });
}

// Befüllt die Oberfläche der Verwaltung der Einsatzorte pro Betriebstätte
async function ladeEinsatzorte() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const betriebsstaetten = unternehmen.Betriebsstaette;
	if (betriebsstaetten.length === 0) {
		systemNachrichtService.zeigeKleineNachricht('Keine Betriebsstätten vorhanden!', 0);
		return;
	}
	// reset des leeren container content falls es Betriebsstätten gibt.
	document.querySelector('#eo-empty-message').remove();
    betriebsstaetten.forEach((bs) => {
		const template = document.querySelector('[eo-panel-template]');
		const newPanel = template.content.cloneNode(true).children[0];
		newPanel.id = `${bs._id}-panel`;
		newPanel.querySelector('[eo-button]').id = bs._id;
		newPanel.querySelector('[eo-button-header]').innerText = `Betriebsstätte: ${`${bs.BetriebsstaetteName} ${!bs.Aktiv ? '(inaktiv)' : ''}`}`;
		document.querySelector('.main-container').appendChild(newPanel);
		displayArray(bs.Einsatzorte, 'eo-', newPanel, bs._id);
	});
}

function confirmNeuerBezugDialog() {
	const bezugName = document.getElementById('neu-bezug-name').value;
	SystemDialogService.instance.confirm(bezugName);
}

// Click handler von neue Netto Be/Abzüge anlegen
async function neuerBezug(prefix) {
	const bezugBez = await SystemDialogService.instance.displayAsync('neu-bezug-dialog');

	// vermeide leere Einträge
	if (!bezugBez.success) {
		return;
	}

	if (bezugBez.data === '') {
		systemNachrichtService.zeigeKleineNachricht('Abbruch: Keine Bezeichnung Angebeben!', 0);
		return;
	}
	// Wir schicken den neuen Bezug an den Server
	const result = await stammdatenService.erstelleNettoBezug(bezugBez.data);
	if (!result) {
		return;
	}
	resetDialogInput('neu-bezug-dialog');
	// Aktualisierung der Oberfläche
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	displayArray(unternehmen.NettoBeAbzuege, 'nba-');

}

function confirmNeueQualifikationDialog() {
	SystemDialogService.instance.confirm({
		BezeichnungNeutral: document.getElementById('neu-quali-neutral').value,
		BezeichnungWeiblich: document.getElementById('neu-quali-female').value,
		BezeichnungMaennlich: document.getElementById('neu-quali-male').value,
		Qualifikationsnummer: parseInt(document.getElementById('neu-quali-number').value, 10),
	});
}

// Click handler von neue Netto Be/Abzüge anlegen
async function neueQualifikation(prefix) {
	// Öffnen und Warten auf Input Dialog...
	const input = await SystemDialogService.instance.displayAsync('neu-quali-dialog');
	// vermeide leere Einträge
	if (!input.success) {
		return;
	}
	if (input.success && input.data.BezeichnungNeutral === '') {
		systemNachrichtService.zeigeKleineNachricht('Abbruch: Keine Bezeichnung angegeben.', '0');
		return;
	}
	// Wir schicken den neuen Bezug an den Server
	const response = await stammdatenService.erstelleQualifikation(input.data);
	if (!response) {
		return;
	}
	resetDialogInput('neu-quali-dialog');
	// Aktualisierung der Oberfläche
	const template = document.querySelector(`[${prefix}template]`);
	// Wir brauchen ein Referenzelement, wo wir die neuen Zeilen im HTML einfügen können
	const referenceElement = document.querySelector(`[${prefix}button]`);
	neueArrayRow(template, referenceElement, response, prefix);
}

function confirmNeuerEinsatzortDialog() {
	SystemDialogService.instance.confirm({
		einsatzort: document.getElementById('neu-einsatzort-name').value,
	});
}

// Click handler für neuen Einsatzort
async function neuerEinsatzort(thisElement) {
	const prefix = 'eo-';
	const bsID = thisElement.id;
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const input = await SystemDialogService.instance.displayAsync('neu-einsatzort-dialog');
	// vermeide leere Einträge oder ungültige Betriebstätte ID
	if (!input.success) {
		return;
	}
	if (input.data.einsatzort === '' || unternehmen.Betriebsstaette.findIndex((bs) => bs._id === bsID) < 0) {
		systemNachrichtService.zeigeKleineNachricht('Abbruch: Keine Bezeichnung angegeben.', '0');
		return;
	}
	// Wir schicken den neuen Bezug an den Server
	const result = await stammdatenService.erstelleEinsatzort(input.data.einsatzort, bsID);
	if (!result) {
		return;
	}
	resetDialogInput('neu-einsatzort-dialog');
	// Aktualisierung der Oberfläche
	const bsPanel = document.getElementById(`${bsID}-panel`);
	const template = bsPanel.querySelector(`[${prefix}template]`);
	// Wir brauchen ein Referenzelement, wo wir die neuen Zeilen im HTML einfügen können
	const referenceElement = bsPanel.querySelector(`[${prefix}button]`);
	neueArrayRow(template, referenceElement, result, prefix, bsID);
}

function confirmNeueBetriebsstaetteDialog() {
	SystemDialogService.instance.confirm({
		bsName: document.getElementById('neu-betrieb-name').value,
	});
}

// Button Control, um eine neue Betriebsstätte anzulegen.
async function neueBetriebsstaette(thisElement) {
	const input = await SystemDialogService.instance.displayAsync('neu-betrieb-dialog');
	// vermeide leere Einträge
	if (!input.success) {
		return;
	}
	if (input.data.bsName === '') {
		systemNachrichtService.zeigeKleineNachricht('Abbruch: Keine Bezeichnung angegeben.', 0);
		return;
	}
	// Wir schicken die neue Betriebsstätte an den Server und erstellen die Oberfläche
	const result = await stammdatenService.erstelleBetriebsstaette(input.data.bsName);
	if (!result) {
		return;
	}
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const unfallV = await basedataService.holeUVBasedataProvider();
	const bundeslaender = await basedataService.holeBundeslaenderBasedataProvider();
	const template = document.querySelector('[bs-panel-template]');
    const newPanel = template.content.cloneNode(true).children[0];
	const betrieb = unternehmen.Betriebsstaette.find((bs) => bs.BetriebsstaetteName === input.data.bsName);
	newPanel.querySelector('[bs-button-header]').innerText = `Betriebsstätte: ${betrieb.BetriebsstaetteName}`;
	const toggleButton = newPanel.querySelector('[aria-label="bs-input-toggle"]');
	toggleButton.id = betrieb._id;
	toggleButton.innerText = 'Betriebsstätte deaktiveren';
	toggleButton.classList.add('entfernen-button');
	const unfallSelect = newPanel.querySelector('[aria-label="bs-input-GesetzlicheUV"');
	fuelleSelectOptionen(unfallSelect, unfallV, 'BNR-UV', 'NAME');
	const bundeslandSelectBS = newPanel.querySelector('[aria-label="bs-input-Bundesland"');
	fuelleSelectOptionen(bundeslandSelectBS, bundeslaender, 'Bundesland', 'Bundesland');
	displayValues(betrieb, '', newPanel, 'bs-input-');
	displayArray(betrieb.Posten, 'bs-input-posten-', newPanel);
	newPanel.id = `${betrieb._id}-panel`;
	thisElement.insertAdjacentElement('beforebegin', newPanel);
	// Zeige neue BS im Select Menü
	await fuelleBetriebSelect();
}

/**
 * Liest die Daten aus der Oberfläche und ersetzt die Stammdaten des Unternehmens
 */
async function speichereUnternehmensdaten() {
	const prefix = 'u-input-';
	let unternehmen = await stammdatenService.holeUnternehmensdaten();
	// Wir benutzen vorsichtshalber eine Kopie, damit wir nicht später Inkonsitenzen bei einem Fehler haben
	unternehmen = JSON.parse(JSON.stringify(unternehmen));
	const panel = document.getElementById(`${prefix}panel`);
	// Holt alle normalen Input Werte
	collectValues(unternehmen, prefix, panel);

	// Bereiche speichern
	unternehmen.Bereiche.forEach((bereich) => {
		// wir suchen die Bereich ID im Frontend und die zugehörigen Inputfelder.
		const zeile = panel.querySelector(`[aria-label="${bereich._id}"]`);
		const zeileBereich = zeile.querySelector(`[aria-label="${prefix}bereiche-Bereich"]`).value;
		const zeileHauptkostenstelle = zeile.querySelector(`[aria-label="${prefix}bereiche-Hauptkostenstelle"]`).value;
		const zeileVerwenden = zeile.querySelector(`[aria-label="${prefix}bereiche-Verwenden"]`).checked;
		bereich.Bereich = zeileBereich;
		bereich.Hauptkostenstelle = zeileHauptkostenstelle;
		bereich.Verwenden = zeileVerwenden;
	});
	updateDebounceUnternehmen(unternehmen);
}

// Liest die Stammdaten der Betriebsstätte aus der Oberfläche und ersetzt diese mit neuen Daten.
async function speichereBetriebsstaette(thisElement) {
	// Das nächste Panel Container Element sollte zu der Betriebsstätte gehören
	const panelID = thisElement.closest('.bs-panel-container').id;
	const bsID = panelID.split('-')[0];
	const prefix = 'bs-input-';
	let unternehmen = await stammdatenService.holeUnternehmensdaten();
	// Wir benutzen vorsichtshalber eine Kopie, damit wir nicht später Inkonsitenzen bei einem Fehler haben
	unternehmen = JSON.parse(JSON.stringify(unternehmen));
	const bsIndex = unternehmen.Betriebsstaette.findIndex((bs) => bs._id === bsID);
	// Falls die ID nicht mit einer Betriebsstätte übereinstimmt, gibt es diese nicht und wir brechen ab...
	if (bsIndex < 0) {
		return;
	}
	const betriebsstaette = unternehmen.Betriebsstaette[bsIndex];
	const panel = document.getElementById(panelID);
	// Holt alle normalen Input Werte
	collectValues(betriebsstaette, prefix, panel);

	// Posten speichern
	betriebsstaette.Posten.forEach((posten) => {
		// wir suchen die Posten ID im Frontend und die zugehörigen Inputfelder.
		const zeile = panel.querySelector(`[aria-label="${posten._id}"]`);
		const zeilePosten = zeile.querySelector(`[aria-label="${prefix}posten-Posten"]`).value;
		const zeileKostenstelle = zeile.querySelector(`[aria-label="${prefix}posten-Kostenstelle"]`).value;
		const zeileBereichID = zeile.querySelector(`[aria-label="${prefix}posten-BereichID"]`).value;
		const zeileVerwenden = zeile.querySelector(`[aria-label="${prefix}posten-Verwenden"]`).checked;
		posten.Posten = zeilePosten;
		posten.Kostenstelle = zeileKostenstelle;
		posten.BereichID = zeileBereichID;
		posten.Verwenden = zeileVerwenden;
	});
	unternehmen.Betriebsstaette[bsIndex] = betriebsstaette;
	updateDebounceUnternehmen(unternehmen);
}

/**
 * Verwendet die ausgewählte Lohnart für das Unternehmen
 * @param {HTMLElement} thisElement Button der geklickt wurde (enthält die Lohnart ID)
 */
async function verwendeLohnart(thisElement) {
	const prefix = 'la-';
	const lohnartID = thisElement.id;
	let unternehmen = await stammdatenService.holeUnternehmensdaten();
	// Wir benutzen vorsichtshalber eine Kopie, damit wir nicht später Inkonsitenzen bei einem Fehler haben
	unternehmen = JSON.parse(JSON.stringify(unternehmen));
	const lohnarten = await basedataService.holeLohnartenBasedataProvider();
	const lohnart = lohnarten.find((la) => la._id === lohnartID);
	if (!lohnart) {
		// Lohnart konnte nicht gefunden werden
		return;
	}
	const isInUnternehmen = unternehmen.Lohnarten.findIndex((la) => la._id === lohnartID);
	if (isInUnternehmen >= 0) {
		// das unternehmen besitzt diese Lohnart schon
        systemNachrichtService.zeigeKleineNachricht('Das Unternehmen besitzt diese Lohnart bereits!', 0);
		return;
	}
	unternehmen.Lohnarten.push(lohnart);
	await stammdatenService.updateUnternehmensdaten(unternehmen);
	// Zeige die neue Lohnart oben an...
	const unternehmenNeu = await stammdatenService.holeUnternehmensdaten();
	displayArray(unternehmenNeu.Lohnarten, 'la-');
}

/**
 * Soll die ausgewählte Lohnart aus dem Unternehmen löschen
 * @param {HTMLElement} thisElement Button der geklickt wurde (enthält die Lohnart ID)
 */
async function entferneLohnart(thisElement) {
	const prefix = 'la-';
	const lohnartID = thisElement.id;
	let unternehmen = await stammdatenService.holeUnternehmensdaten();
	// Wir benutzen vorsichtshalber eine Kopie, damit wir nicht später Inkonsitenzen bei einem Fehler haben
	unternehmen = JSON.parse(JSON.stringify(unternehmen));
	const lohnartIndex = unternehmen.Lohnarten.findIndex((la) => la._id === lohnartID);
	if (lohnartIndex < 0) {
		// Lohnart konnte nicht gefunden werden
		return;
	}
	// Lohnart aus dem Unternehmen löschen
	unternehmen.Lohnarten.splice(lohnartIndex, 1);
	await stammdatenService.updateUnternehmensdaten(unternehmen);
	document.getElementById(`${prefix}${lohnartID}`).remove();
}

/**
 * Soll die ausgewählte Lohnart aus dem Unternehmen löschen
 * @param {HTMLElement} thisElement Button der geklickt wurde (enthält die Lohnart ID)
 */
async function entferneBezug(thisElement) {
	const prefix = 'nba-';
	const bezugID = thisElement.id;
	let unternehmen = await stammdatenService.holeUnternehmensdaten();
	// Wir benutzen vorsichtshalber eine Kopie, damit wir nicht später Inkonsitenzen bei einem Fehler haben
	unternehmen = JSON.parse(JSON.stringify(unternehmen));
	const lohnartIndex = unternehmen.NettoBeAbzuege.findIndex((nba) => nba._id === bezugID);
	if (lohnartIndex < 0) {
		// Lohnart konnte nicht gefunden werden
		return;
	}
	// Lohnart aus dem Unternehmen löschen
	unternehmen.NettoBeAbzuege.splice(lohnartIndex, 1);
	await stammdatenService.updateUnternehmensdaten(unternehmen);
	document.getElementById(`${prefix}${bezugID}`).remove();
}

/**
 * Speichert die aktuelle Änderung der bearbeiteten Zeile
 * @param {HTMLElement} thisElement input Feld, das bearbeitet wurde
 */
async function speichereLohnart(thisElement) {
	// Zeile ist der parent des input elements
	const zeile = thisElement.parentNode;
	// wir brauchen nur die ID, also schließen wir den prefix (la-) aus.
	const zeileID = zeile.id.split('-')[1];
	let unternehmen = await stammdatenService.holeUnternehmensdaten();
	// Wir benutzen vorsichtshalber eine Kopie, damit wir nicht später Inkonsitenzen bei einem Fehler haben
	unternehmen = JSON.parse(JSON.stringify(unternehmen));
	const lohnart = unternehmen.Lohnarten.find((la) => la._id === zeileID);
	if (!lohnart) {
		// Lohnart ist nicht im Unternehmen findbar...
		return;
	}
	collectValues(lohnart, 'la-', zeile);
	updateDebounceUnternehmen(unternehmen);
}

/**
 * Speichert die aktuelle Änderung der bearbeiteten Zeile
 * @param {HTMLElement} thisElement input Feld, das bearbeitet wurde
 */
async function speichereBezug(thisElement) {
	// Zeile ist der parent des input elements
	const zeile = thisElement.parentNode;
	// wir brauchen nur die ID, also schließen wir den prefix (nba-) aus.
	const zeileID = zeile.id.split('-')[1];
	let unternehmen = await stammdatenService.holeUnternehmensdaten();
	// Wir benutzen vorsichtshalber eine Kopie, damit wir nicht später Inkonsitenzen bei einem Fehler haben
	unternehmen = JSON.parse(JSON.stringify(unternehmen));
	const bezug = unternehmen.NettoBeAbzuege.find((nba) => nba._id === zeileID);
	if (!bezug) {
		// Lohnart ist nicht im Unternehmen findbar...
		return;
	}
	collectValues(bezug, 'nba-', zeile);
	updateDebounceUnternehmen(unternehmen);
}

/**
 * Speichert die aktuelle Änderung der bearbeiteten Zeile
 * @param {HTMLElement} thisElement input Feld, das bearbeitet wurde
 */
async function speichereQuali(thisElement) {
	// Zeile ist der parent des input elements
	const zeile = thisElement.parentNode;
	// wir brauchen nur die ID, also schließen wir den prefix (qu-) aus.
	const zeileID = zeile.id.split('-')[1];
	let unternehmen = await stammdatenService.holeUnternehmensdaten();
	// Wir benutzen vorsichtshalber eine Kopie, damit wir nicht später Inkonsitenzen bei einem Fehler haben
	unternehmen = JSON.parse(JSON.stringify(unternehmen));
	const quali = unternehmen.Qualifikationen.find((qu) => qu._id === zeileID);
	if (!quali) {
		// Lohnart ist nicht im Unternehmen findbar...
		return;
	}
	collectValues(quali, 'qu-', zeile);
	updateDebounceUnternehmen(unternehmen);
}

/**
 * Speichert die aktuelle Änderung der bearbeiteten Zeile
 * @param {HTMLElement} thisElement input Feld, das bearbeitet wurde
 */
async function speichereEinsatzort(thisElement) {
	// Zeile ist der parent des input elements
	const zeile = thisElement.parentNode;
	// wir brauchen die einsatzortID und betriebsstätteID, also schließen wir den prefix (eo-) aus.
	const einsatzortID = zeile.id.split('-')[1];
	const bsID = zeile.id.split('-')[2];
	let unternehmen = await stammdatenService.holeUnternehmensdaten();
	// Wir benutzen vorsichtshalber eine Kopie, damit wir nicht später Inkonsitenzen bei einem Fehler haben
	unternehmen = JSON.parse(JSON.stringify(unternehmen));
	const betrieb = unternehmen.Betriebsstaette.find((bs) => bs._id === bsID);
	if (!betrieb) {
		// Lohnart ist nicht im Unternehmen findbar...
		return;
	}
	const einsatzort = betrieb.Einsatzorte.find((qu) => qu._id === einsatzortID);
	if (!einsatzort) {
		// Lohnart ist nicht im Unternehmen findbar...
		return;
	}
	collectValues(einsatzort, 'eo-', zeile);
	updateDebounceUnternehmen(unternehmen);
}

function confirmToggleBetriebDialog() {
	SystemDialogService.instance.confirm();
}

/**
 * Setzt eine Betriebsstätte als nicht verwendet bzw. ungültig.
 * @param {HTMLElement} thisElement Entfernen Button, der die ID der Betriebsstätte enthält.
 */
async function toggleBetriebsstaette(thisElement) {
	let unternehmen = await stammdatenService.holeUnternehmensdaten();
	// Wir benutzen vorsichtshalber eine Kopie, damit wir nicht später Inkonsitenzen bei einem Fehler haben
	unternehmen = JSON.parse(JSON.stringify(unternehmen));
	const bsIndex = unternehmen.Betriebsstaette.findIndex((bs) => bs._id === thisElement.id);
	if (bsIndex < 0) {
		systemNachrichtService.zeigeKleineNachricht('Betriebsstätte konnte nicht gefunden werden!', -1);
		return;
	}
	const headerText = `Betriebsstätte '${unternehmen.Betriebsstaette[bsIndex].BetriebsstaetteName}' ${!unternehmen.Betriebsstaette[bsIndex].Aktiv ? 'aktivieren' : 'deaktivieren'}?`;
	document.getElementById('toggle-bs-header').innerText = headerText;
	const isSicherText = `Wollen Sie wirklich die Betriebsstätte '${unternehmen.Betriebsstaette[bsIndex].BetriebsstaetteName}' ${!unternehmen.Betriebsstaette[bsIndex].Aktiv ? 'wieder' : 'nicht mehr'} in Ihrem Unternhemen verwenden?`;
	document.getElementById('toggle-bs-text').innerText = isSicherText;
	const isSicher = await SystemDialogService.instance.displayAsync('toggle-bs-dialog');
	if (!isSicher.success) {
		return;
	}
	unternehmen.Betriebsstaette[bsIndex].Aktiv = !unternehmen.Betriebsstaette[bsIndex].Aktiv;
	await stammdatenService.updateUnternehmensdaten(unternehmen);
	if (!unternehmen.Betriebsstaette[bsIndex].Aktiv) {
		document.getElementById(`${thisElement.id}-panel`).querySelector('[bs-button-header]').innerText += ' (inaktiv)';
		thisElement.classList.remove('entfernen-button');
		thisElement.classList.add('verwenden-button');
		thisElement.innerText = 'Betriebsstätte reaktiveren';
	} else {
		document.getElementById(`${thisElement.id}-panel`).querySelector('[bs-button-header]').innerText = unternehmen.Betriebsstaette[bsIndex].BetriebsstaetteName;
		thisElement.classList.remove('verwenden-button');
		thisElement.classList.add('entfernen-button');
		thisElement.innerText = 'Betriebsstätte deaktivieren';
	}
	systemNachrichtService.zeigeKleineNachricht('Änderungen wurden erfolgreich übernommen!', 1);
	await fuelleBetriebSelect();
}

async function ladeSchriftverkehr() {
	await holeVorlagen();
	await holeMustervorlagen();
	await holeFesteTextmarken();
	await holeTextmarken();
}

async function holeMustervorlagen() {
	const vorlagen = await basedataService.holeVorlagenBasedataProvider();
	const sortedVorlagen = vorlagen.sort((a, b) => (a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())));
	const liste = document.querySelector('#schriftverkehr-mustervorlagen');
	liste.innerHTML = '';
	sortedVorlagen.forEach((item) => {
		const newItem = erstelleMustervorlagenElement(item);
		liste.appendChild(newItem);
	});
}

/**
 * Erstellt oder Updated ein Vorlagendokument
 * - Bei der Erstellung wird zusätzlich nach einem Dateinamen gefragt.
 */
 async function speichereVorlage(nameChange = false) {
	// eine leere File speichern wir nicht.
	if (_.isEmpty(window.myServices.editorService.getCurrentFile())) {
		return;
	}
	try {
		const mdContent = window.myServices.editorService.getMarkdown();
		// Nur Updaten falls es noch Änderungen gibt.
		const oldObject = window.myServices.editorService.getCurrentFile();
		if (oldObject.Inhalt !== mdContent || nameChange) {
			oldObject.Inhalt = mdContent;
			const response = await fetch('/neolohn/api/vorlage', {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				mode: 'cors',
				body: JSON.stringify(oldObject),
			});
			if (!response.ok) {
				if (response.status === 413) {
					systemNachrichtService.zeigeKleineNachricht('Vorlagedatei zu groß, bitte nur kleine Bilder (weniger als 100KB) einfügen!', -1);
					return;
				}
				systemNachrichtService.zeigeKleineNachricht('Speichern fehlgeschlagen!', -1);
				return;
			}
			const result = await response.json();
			window.myServices.editorService.setCurrentFile(result);
			systemNachrichtService.zeigeKleineNachricht('Speichern erfolgreich!', 1);
			await holeVorlagen(result._id);
		}
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Speichern fehlgeschlagen!', -1);
	}
}

/**
 * markiert die aktuelle Auswahl für bessere Übersicht.
 */
function aktiveVorlageMarkieren(elementId) {
	const items = document.querySelectorAll('.dokument-item-active');
	items.forEach((elem) => elem.classList.remove('dokument-item-active'));
	const auswahl = document.getElementById(elementId);
	if (!auswahl) {
		return;
	}
	auswahl.classList.add('dokument-item-active');
}

/**
 * Lädt die Vorlagen Dokumente vom Server und zeigt sie in der Liste an.
 */
async function holeVorlagen(aktivesElementId) {
	try {
		const response = await fetch('/neolohn/api/vorlage');
		if (response.ok) {
			const result = await response.json();
			const sortedVorlagen = result.sort((a, b) => (a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())));
			const liste = document.querySelector('#schriftverkehr-dokumente-liste');
			liste.innerHTML = '';
			sortedVorlagen.forEach((item) => {
				const newItem = erstelleVorlagenElement(item);
				liste.appendChild(newItem);
			});
		}
		if (aktivesElementId) {
			aktiveVorlageMarkieren(aktivesElementId);
		}
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Laden von Dokumenten fehlgeschlagen!', -1);
	}
}

/**
 * Liest ein einzelnes Vorlagen Objekt aus.
 * @param {string} vorlageID
 * @returns vorlagenobjekt
 */
async function fetchVorlage(vorlageID) {
	try {
		const response = await fetch(`/neolohn/api/dokument/md/${vorlageID}`);
		if (!response.ok) {
			throw new Error(`HTTP error, status = ${response.status}`);
		}
		const result = await response.json();
		return result;
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Fehler beim Laden der Vorlage!', -1);
		return false;
	}
}

/**
 * Erstellt das HTML Element der Vorlage in der Liste der Vorlagen inklusive Event Handler.
 * @param {object} vorlageObjekt
 */
function erstelleVorlagenElement(vorlageObjekt) {
	const template = document.querySelector('[schriftverkehr-item-template]');
	const newItem = template.content.cloneNode(true).children[0];
	newItem.innerText = `${vorlageObjekt.Name} (${dayjs(vorlageObjekt.DatumUpdate).format('DD.MM.YYYY, HH:mm')})`;
	newItem.id = vorlageObjekt._id;
	newItem.addEventListener('click', async () => {
		// Speichert die alte ausgewählte Vorlage, beim Wechsel in eine andere
		await speichereVorlage();
		window.myServices.editorService.setMarkdown(vorlageObjekt.Inhalt);
		window.myServices.editorService.setCurrentFile(vorlageObjekt);
		document.getElementById('schriftverkehr-filename-edit').value = vorlageObjekt.Name;
		aktiveVorlageMarkieren(newItem.id);
		document.getElementById('editor').style.visibility = 'visible';
		// editierbarer Inhalt
		const editor = document.getElementById('editor');
		editor.style.visibility = 'visible';
		editor.classList.remove('uneditable');
		editor.querySelector('.ProseMirror.toastui-editor-contents').setAttribute('contenteditable', 'true');
		document.getElementById('schriftverkehr-filename-edit').disabled = false;
	});
	return newItem;
}

/**
 * Erstellt das HTML Element der Vorlage in der Liste der Vorlagen inklusive Event Handler.
 * @param {object} vorlageObjekt
 */
function erstelleMustervorlagenElement(vorlageObjekt) {
	const template = document.querySelector('[schriftverkehr-item-template]');
	const newItem = template.content.cloneNode(true).children[0];
	newItem.innerText = vorlageObjekt.Name;
	newItem.id = vorlageObjekt._id;
	newItem.addEventListener('click', async () => {
		// Speichert die alte ausgewählte Vorlage, beim Wechsel in eine andere
		await speichereVorlage();
		window.myServices.editorService.setMarkdown(vorlageObjekt.Inhalt);
		window.myServices.editorService.setCurrentFile(vorlageObjekt);
		document.getElementById('schriftverkehr-filename-edit').value = vorlageObjekt.Name;
		aktiveVorlageMarkieren(newItem.id);
		// uneditierbarer Inhalt
		const editor = document.getElementById('editor');
		editor.style.visibility = 'visible';
		editor.classList.add('uneditable');
		editor.querySelector('.ProseMirror.toastui-editor-contents').setAttribute('contenteditable', 'false');
		document.getElementById('schriftverkehr-filename-edit').disabled = true;
	});
	return newItem;
}

// Neues Dokument soll erstellt werden... dafür wird der Editor Inhalt resettet
async function neueVorlage() {
	await speichereVorlage();
	await fuelleVorlagenSelect();
	const input = await SystemDialogService.instance.displayAsync('vorlage-speichern-dialog');
	if (!input.success) {
		return;
	}
	const name = input.data.name === '' ? 'unbenannte Vorlage' : input.data.name;
	const vorlageID = input.data.vorlageID;
	let mdContent = '';
	// wenn eine Vorlage ausgewählt wurde müssen wir zwischen Mustervorlage und eigener Vorlage unterscheiden.
	if (!_.isEmpty(vorlageID)) {
		const select = document.querySelector('#vorlage-neu-vorlagen');
		const isMustervorlage = select.selectedOptions[0].parentElement.label === 'Mustervorlagen';
		let vorlagenObjekt;
		if (isMustervorlage) {
			const mustervorlagen = await basedataService.holeVorlagenBasedataProvider();
			vorlagenObjekt = mustervorlagen.find((vorlage) => vorlage._id === vorlageID);
		} else {
			vorlagenObjekt = await fetchVorlage(vorlageID);
		}
		if (!_.isEmpty(vorlagenObjekt)) {
			mdContent = vorlagenObjekt.Inhalt;
		}
	}
	const vorlageNeu = await fetchNeueVorlage(name, mdContent);
	window.myServices.editorService.setCurrentFile(vorlageNeu);
	window.myServices.editorService.setMarkdown(vorlageNeu.Inhalt);
	document.getElementById('schriftverkehr-filename-edit').value = vorlageNeu.Name;
	// editierbarer Inhalt
	const editor = document.getElementById('editor');
	editor.style.visibility = 'visible';
	editor.classList.remove('uneditable');
	document.getElementById('schriftverkehr-filename-edit').disabled = false;
	editor.querySelector('.ProseMirror.toastui-editor-contents').setAttribute('contenteditable', 'true');
	systemNachrichtService.zeigeKleineNachricht('Speichern erfolgreich!', 1);
	// neu laden der Dokumente bei Erstellung, um das neue anzuzeigen
	await holeVorlagen(vorlageNeu._id);
	aktiveVorlageMarkieren();
}

/**
 * Sendet die fetch POST Anfrage an den Server, um eine neue Vorlage zu erstellen
 * @param {string} vorlageName
 * @param {string} vorlageInhalt Markdown Content.
 * @returns neues Vorlagenobjekt
 */
async function fetchNeueVorlage(vorlageName, vorlageInhalt) {
	try {
		const response = await fetch('/neolohn/api/vorlage', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			mode: 'cors',
			body: JSON.stringify({ Inhalt: vorlageInhalt, Name: vorlageName }),
		});
		if (!response.ok) {
			if (response.status === 413) {
				systemNachrichtService.zeigeKleineNachricht('Vorlagedatei zu groß, bitte nur kleine Bilder (weniger als 100KB) einfügen!', -1);
			}
			throw new Error(`HTTP error, status = ${response.status}`);
		}
		const result = await response.json();
		return result;
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Speichern fehlgeschlagen!', -1);
		return false;
	}
}

/**
 * Setzt den Input Namen auf den Vorlagennamen.
 */
function setzeVorlageNeuName(thisElement) {
	if (thisElement.value === '') {
		return;
	}
	const inputName = document.querySelector('#vorlage-neu-name');
	const selectedName = thisElement.selectedOptions[0].innerText;
	inputName.value = `${selectedName} (Kopie)`;
	inputName.focus();
}

/**
 * Click Handler: Übermittlung der Daten für die neue Vorlage aus dem Dialog Feld
 */
async function confirmVorlageNeuDialog() {
	SystemDialogService.instance.confirm({
		name: document.getElementById('vorlage-neu-name').value,
		vorlageID: document.getElementById('vorlage-neu-vorlagen').value
	});
}

/**
 * Lädt die Vorlagen Dokumente vom Server und zeigt sie in dem Select an.
 */
async function fuelleVorlagenSelect() {
	try {
        const select = document.querySelector('#vorlage-neu-vorlagen');
        select.innerHTML = '';
		const response = await fetch('/neolohn/api/vorlage');
		if (!response.ok) {
            const result = await response.text();
            systemNachrichtService.zeigeKleineNachricht(result, 0);
            throw new Error(`HTTP error, status = ${response.status}`);
        }
		const emptyOption = document.createElement('option');
		emptyOption.value = '';
		emptyOption.innerText = 'keine Auswahl';
		select.appendChild(emptyOption);
        const listeEigeneVorlagen = await response.json();
        // Option Gruppe Eigene Vorlagen
        if (listeEigeneVorlagen.length > 0) {
            listeEigeneVorlagen.sort((a, b) => a.Name.localeCompare(b.Name));
            select.appendChild(erstelleOptionGroup('eigene Vorlagen', listeEigeneVorlagen, '_id', 'Name'));
        }
        const listeStandardVorlagen = await basedataService.holeVorlagenBasedataProvider();
        // Option Gruppe Mustervorlagen
        if (listeStandardVorlagen.length > 0) {
            listeStandardVorlagen.sort((a, b) => a.Name.localeCompare(b.Name));
            select.appendChild(erstelleOptionGroup('Mustervorlagen', listeStandardVorlagen, '_id', 'Name'));
        }
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Laden von Vorlagen fehlgeschlagen!', -1);
	}
}

// Fügt den Inhalt der Variable ein in den Editor
function addVariable(textmarke) {
	const editor = document.getElementById('editor');
	// falls wir im nicht edit modus sind, brechen wir ab.
	if (editor.classList.contains('uneditable')) {
		return;
	}
	window.myServices.editorService.replaceCurrentSelection(` ${textmarke} `);
	debounceSpeichereVorlage();
}

// Lädt die Textmarken in die Oberfläche
async function holeTextmarken() {
	try {
		const response = await fetch('/neolohn/api/textmarken-basedataprovider');
		if (response.ok) {
			const result = await response.json();
			const textmarken = result.filter((tm) => tm.Collection !== 'Entlohnungsart');
			const template = document.querySelector('[schriftverkehr-item-template]');
			const liste = document.querySelector('#schriftverkehr-variablen-flex');
			liste.innerHTML = '';
			textmarken.sort((a, b) => (a.Textmarke.toUpperCase().localeCompare(b.Textmarke.toUpperCase())));
			textmarken.forEach((item) => {
				const newItem = template.content.cloneNode(true).children[0];
				newItem.innerText = item.Textmarke.replaceAll(/(\{|\})/g, '');
				newItem.innerText = item.Textmarke;
				newItem.id = item._id;
				newItem.addEventListener('click', () => {
					addVariable(item.Textmarke);
				});
				liste.appendChild(newItem);
			});
		}
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Laden von Variablen fehlgeschlagen!', -1);
	}
}

// Lädt die Textmarken in die Oberfläche
async function holeFesteTextmarken() {
	try {
		const response = await fetch('/neolohn/api/festetextmarken-basedataprovider');
		if (response.ok) {
			const result = await response.json();
			const template = document.querySelector('[schriftverkehr-item-template]');
			const liste = document.querySelector('#schriftverkehr-variablen-fest');
			liste.innerHTML = '';
			result.sort((a, b) => (a.Textmarke.toUpperCase().localeCompare(b.Textmarke.toUpperCase())));
			result.forEach((item) => {
				const newItem = template.content.cloneNode(true).children[0];
				newItem.innerText = item.Textmarke.replaceAll(/(\{|\})/g, '');
				newItem.id = item._id;
				newItem.addEventListener('click', () => {
					addVariable(item.Textmarke);
				});
				liste.appendChild(newItem);
			});
		}
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Laden von Variablen fehlgeschlagen!', -1);
	}
}

async function editDocName(thisElement) {
	const currentFile = window.myServices.editorService.getCurrentFile();
	if (_.isEmpty(currentFile) || thisElement.value === '') {
		return;
	}
	window.myServices.editorService.setFileName(thisElement.value);
	document.getElementById(currentFile._id).innerText = `${thisElement.value} (${dayjs(currentFile.DatumUpdate).format('DD.MM.YYYY, HH:mm')})`;
	debounceSpeichereVorlage(true);
}

async function neuerBereich() {
	const result = await SystemDialogService.instance.displayAsync('neu-bereich-dialog');
	if (!result.success) {
		return;
	}
	if (result.data.BereichBezeichnung === '') {
		systemNachrichtService.zeigeKleineNachricht('Bereichsangaben unvollständig! Bitte wiederholen sie die Aktion.', 0);
		return;
	}
	const response = await stammdatenService.erstelleBereich(result.data.BereichBezeichnung, result.data.BereichNummer);
	if (!response) {
		return;
	}
	resetDialogInput('neu-bereich-dialog');
	// Aktualisierung der Oberfläche
	const prefix = 'u-input-bereiche-';
	const template = document.querySelector(`[${prefix}template]`);
	// Wir brauchen ein Referenzelement, wo wir die neuen Zeilen im HTML einfügen können
	const referenceElement = document.querySelector(`[${prefix}button]`);
	neueArrayRow(template, referenceElement, response, prefix);
}

async function confirmNeuerBereichDialog() {
	SystemDialogService.instance.confirm({
		BereichBezeichnung: document.getElementById('neu-bereich-name').value,
		BereichNummer: document.getElementById('neu-bereich-nummer').value
	});
}

async function neuerPosten(thisElement) {
	// Wir wollen die Bereiche auswählen können
	const postenSelect = document.getElementById('neu-posten-bereich');
	const bereiche = stammdatenService.unternehmensobjekt.Bereiche;
	fuelleSelectOptionen(postenSelect, bereiche, '_id', 'Bereich');

	const result = await SystemDialogService.instance.displayAsync('neu-posten-dialog');
	if (!result.success) {
		return;
	}
	if (result.data.PostenBezeichnung === '') {
		systemNachrichtService.zeigeKleineNachricht('Postenangaben unvollständig! Bitte wiederholen sie die Aktion.', 0);
		return;
	}
	const betriebPanel = thisElement.closest('.bs-panel-container');
	const betriebID = betriebPanel.id.split('-')[0];
	const response = await stammdatenService.erstellePosten(result.data.PostenBezeichnung, result.data.PostenBereich, result.data.PostenNummer, betriebID);
	if (!response) {
		return;
	}
	resetDialogInput('neu-posten-bereich');
	// Aktualisierung der Oberfläche
	const prefix = 'bs-input-posten-';
	const template = betriebPanel.querySelector(`[${prefix}template]`);
	// Wir brauchen ein Referenzelement, wo wir die neuen Zeilen im HTML einfügen können
	const referenceElement = betriebPanel.querySelector(`[${prefix}button]`);
	neueArrayRow(template, referenceElement, response, prefix);
}

async function confirmNeuerPostenDialog() {
	SystemDialogService.instance.confirm({
		PostenBezeichnung: document.getElementById('neu-posten-name').value,
		PostenBereich: document.getElementById('neu-posten-bereich').value,
		PostenNummer: document.getElementById('neu-posten-nummer').value
	});
}

async function ladeBenutzerverwaltung() {
	toggleLoader();
	const interneExtraUser = await stammdatenService.ladeInterneMitarbeiter(true);
	const externeUser = await stammdatenService.ladeExterneMitarbeiter();
	const internPanel = document.getElementById('bw-intern-panel');
	displayArray(interneExtraUser, 'bw-intern-', internPanel);
	const externPanel = document.getElementById('bw-extern-panel');
	displayArray(externeUser, 'bw-extern-', externPanel);
	toggleLoader();
}

/**
 * Update User Roles Button Click Handler
 * @param {HTMLElement} thisElement Button auf den geklickt wurde
 */
async function benutzerAddRolleClick(thisElement) {
	const userZeile = thisElement.closest('.bw-zeile');
	const userId = userZeile.getAttribute('aria-label');
	let rollen = await stammdatenService.ladeBenutzerRollen();
	rollen = rollen.filter((rolle) => rolle.name !== 'mitarbeiter');
	const rolleSelect = document.getElementById('benutzer-add-rolle');
	fuelleSelectOptionen(rolleSelect, rollen, 'id', 'name');
	const result = await SystemDialogService.instance.displayAsync('benutzer-rolle-add-dialog');
	if (result.success) {
		await stammdatenService.addBenutzerRolle(result.data.role, userId);
		await ladeBenutzerverwaltung();
	}
}

function confirmAddUserRole() {
	const rolleSelect = document.getElementById('benutzer-add-rolle');
	SystemDialogService.instance.confirm({
		id: rolleSelect.value,
		role: rolleSelect.options[rolleSelect.selectedIndex].innerText
	});
}

/**
 * Remove User Roles Button Click Handler
 * @param {HTMLElement} thisElement Button auf den geklickt wurde
 */
async function benutzerDelRolleClick(thisElement) {
	const userId = thisElement.closest('.bw-zeile').getAttribute('aria-label');
	let rollen = await stammdatenService.ladeBenutzerRollen();
	rollen = rollen.filter((rolle) => rolle.name !== 'mitarbeiter');
	const rolleSelect = document.getElementById('benutzer-del-rolle');
	fuelleSelectOptionen(rolleSelect, rollen, 'id', 'name');
	const result = await SystemDialogService.instance.displayAsync('benutzer-rolle-del-dialog');
	if (result.success) {
		await stammdatenService.removeBenutzerRolle(result.data.role, userId);
		await ladeBenutzerverwaltung();
	}
}

function confirmDelUserRole() {
	const rolleSelect = document.getElementById('benutzer-del-rolle');
	SystemDialogService.instance.confirm({
		id: rolleSelect.value,
		role: rolleSelect.options[rolleSelect.selectedIndex].innerText
	});
}

async function benutzerNeueRolleClickMA() {
	let rollen = await stammdatenService.ladeBenutzerRollen();
	const rolleSelect = document.getElementById('mitarbeiter-neu-rolle');
	rollen = rollen.filter((rolle) => rolle.name !== 'mitarbeiter');
	fuelleSelectOptionen(rolleSelect, rollen, 'id', 'name');
	const interneUser = await stammdatenService.ladeInterneMitarbeiter(false);
	const maSelect = document.getElementById('mitarbeiter-neu-id');
	fuelleSelectOptionen(maSelect, interneUser, 'id', ['email', 'lastName', 'firstName']);
	const result = await SystemDialogService.instance.displayAsync('mitarbeiter-rolle-neu-dialog');
	if (result.success) {
		await stammdatenService.addBenutzerRolle(result.data.role, result.data.userId);
		await ladeBenutzerverwaltung();
	}
}

function confirmNewUserRoleMA() {
	const rolleSelect = document.getElementById('mitarbeiter-neu-rolle');
	const maSelect = document.getElementById('mitarbeiter-neu-id');
	SystemDialogService.instance.confirm({
		id: rolleSelect.value,
		role: rolleSelect.options[rolleSelect.selectedIndex].innerText,
		userId: maSelect.value,
	});
}

/**
 * On Input Handler für die Aktiv Checkbox des Benutzers
 * @param {HTMLElement} thisElement
 */
async function toggleBenutzerAktiv(thisElement) {
	const userId = thisElement.closest('.bw-zeile').getAttribute('aria-label');
	const aktivValue = thisElement.checked;
	if (aktivValue) {
		await stammdatenService.reaktivereBenutzer(userId);
		return;
	}
	await stammdatenService.deaktivereBenutzer(userId);
}

async function erstelleExternenBenutzer() {
	let rollen = await stammdatenService.ladeBenutzerRollen();
	// externe Mitarbeiter sollten nicht mitarbeiter werden
	rollen = rollen.filter((rolle) => rolle.name !== 'mitarbeiter');
	const rolleSelect = document.getElementById('benutzer-neu-role');
	fuelleSelectOptionen(rolleSelect, rollen, 'id', 'name');
	const result = await SystemDialogService.instance.displayAsync('erstelle-benutzer-extern-dialog');
	if (result.success) {
		await stammdatenService.postExternalUser(result.data);
		await ladeBenutzerverwaltung();
		resetDialogInput('erstelle-benutzer-extern-dialog');
	}
}

function confirmNewExternalUser() {
	const rolleSelect = document.getElementById('benutzer-neu-role');
	SystemDialogService.instance.confirm({
		firstName: document.getElementById('benutzer-neu-firstName').value,
		lastName: document.getElementById('benutzer-neu-lastName').value,
		email: document.getElementById('benutzer-neu-email').value,
		role: rolleSelect.options[rolleSelect.selectedIndex].innerText,
	});
}

async function ladePausenmodelle() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const select = document.querySelector('[aria-label="u-input-Pausenmodelle"]');
	fuelleSelectOptionen(select, unternehmen.Pausenmodelle, '_id', 'Bezeichnung', true, 'Pausenmodell wählen');
	// wir wählen automatisch das erste Element aus, falls vorhanden.
	select.value = unternehmen.Pausenmodelle.length > 0 ? unternehmen.Pausenmodelle[0]._id : '';
	selectPausenmodell(select.value);
}

/**
 * Lädt das ausgewählte Pausenmodell in die Oberfläche.
 * @param {string} value ausgewähltes Pausenmodell (id)
 */
function selectPausenmodell(value) {
	// select auf übergebene value setzen, falls von außerhalb getriggert.
	document.querySelector('[aria-label="u-input-Pausenmodelle"]').value = value;
	const unternehmen = stammdatenService.unternehmen;
	const pausenModellFieldset = document.getElementById('u-Pausenmodelle');
	const selectedPausenmodell = unternehmen.Pausenmodelle.find((pm) => pm._id === value);
	const pausenListe = pausenModellFieldset.querySelector('#pausenmodell-pausen');
	[...pausenListe.querySelectorAll('.pausen-liste')].forEach((element) => element.remove());
	pausenModellFieldset.querySelector('[aria-label="u-input-pausen-Bezeichnung"]').value = '';
	if (!_.isEmpty(selectedPausenmodell)) {
		const template = document.querySelector('[u-input-pausen-template]');
		pausenModellFieldset.querySelector('[aria-label="u-input-pausen-Bezeichnung"]').value = selectedPausenmodell.Bezeichnung;
		selectedPausenmodell.Pausenstandard.forEach((pause) => {
			const newRow = template.content.cloneNode(true).children[0];
			displayValues(pause, '', newRow, 'u-input-pausen-');
			pausenListe.appendChild(newRow);
		});
	}
}

/**
 * Erstellt neue Zeile im Pausenmodell
 */
function pausenmodellAddPause() {
	const template = document.querySelector('[u-input-pausen-template]');
	const pausenListe = document.querySelector('#pausenmodell-pausen');
	const newRow = template.content.cloneNode(true).children[0];
	pausenListe.appendChild(newRow);
	setTimeout(() => newRow.querySelector('input').focus(), 100);
}

/**
 * Entfernt die ausgewählte Pause
 * @param {HTMLElement} thisElement Enternen Icon auf der Pausenzeile
 */
function pausenmodellRemovePause(thisElement) {
	// der Parent des Icons ist die Pausen Zeile, wir entfernen diese und speichern das Pausenmodell
	thisElement.parentNode.remove();
	speicherePausenmodell();
}

/**
 * Speichert das gewählte Pausenmodell im Unternehmen ab.
 */
async function speicherePausenmodell() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const pausenmodellSelect = document.querySelector('[aria-label="u-input-Pausenmodelle"]');
	if (_.isEmpty(pausenmodellSelect.value)) {
		return;
	}
	const pausenmodellSelection = unternehmen.Pausenmodelle.find((pm) => pm._id === pausenmodellSelect.value);
	if (_.isEmpty(pausenmodellSelection)) {
		systemNachrichtService.zeigeKleineNachricht('Pausenmodell nicht im Unternehmen gefunden!', -1);
		return;
	}
	// Speichere Inputdaten ins Pausenmodell
	pausenmodellSelection.Bezeichnung = document.querySelector('[aria-label="u-input-pausen-Bezeichnung"]').value;
	// Update der Bezeichnung im Select.
	pausenmodellSelect.options[pausenmodellSelect.selectedIndex].innerText = pausenmodellSelection.Bezeichnung;
	const pausenHTML = document.querySelectorAll('.pausen-liste');
	const pausenStandardNeu = [];
	pausenHTML.forEach((pause) => {
		const pauseObject = {
			Nach: '',
			Dauer: 0,
			Bezahlt: false
		};
		collectValues(pauseObject, 'u-input-pausen-', pause);
		pausenStandardNeu.push(pauseObject);
	});
	pausenmodellSelection.Pausenstandard = pausenStandardNeu;
	updateDebounceUnternehmen(unternehmen);
}

/**
 * Event Handler für den "neu erstellen" Button bei den Pausenmodellen.
 */
async function erstellePausenmodell() {
	const pausenmodellNeu = {};
	const selectedPausenmodell = document.querySelector('[aria-label="u-input-Pausenmodelle"]').value;
	// Speichere Inputdaten ins Pausenmodell
	pausenmodellNeu.Bezeichnung = document.querySelector('[aria-label="u-input-pausen-Bezeichnung"]').value;
	// Falls wir aus einer anderen Pausenmodell erstellen, übernehmen wir den Namen mit Kopie
	if (selectedPausenmodell !== '') {
		pausenmodellNeu.Bezeichnung += ' (Kopie)';
	}
	const pausenHTML = document.querySelectorAll('.pausen-liste');
	const pausenStandardNeu = [];
	pausenHTML.forEach((pause) => {
		const pauseObject = {
			Nach: '',
			Dauer: 0,
			Bezahlt: false
		};
		collectValues(pauseObject, 'u-input-pausen-', pause);
		pausenStandardNeu.push(pauseObject);
	});
	pausenmodellNeu.Pausenstandard = pausenStandardNeu;
	if (pausenmodellNeu.Bezeichnung === '') {
		pausenmodellNeu.Bezeichnung = 'unbenannt';
	}
	const result = await stammdatenService.erstellePausenmodell(pausenmodellNeu);
	// Abbruch bei fehlerhafter Antwort.
	if (_.isEmpty(result)) {
		return;
	}
	// Falls der Vorgang erfolgreich ist, müssen wir das neue Pausenmodell im Select aufnehmen.
	await ladePausenmodelle();
	selectPausenmodell(result);
}

/**
 * lädt/speichert die Einstellungen der Apps
 */
async function ladeAppEinstellungen() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const panelEinstellungen = document.getElementById('u-app');
	displayValues(unternehmen.MitarbeiterAppEinstellungen, '', panelEinstellungen, 'u-');
}

async function speichereAppEinstellungen() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const panelEinstellungen = document.getElementById('u-app');
	collectValues(unternehmen.MitarbeiterAppEinstellungen, 'u-', panelEinstellungen);
	updateDebounceUnternehmen(unternehmen);
}

async function ladeCronjobEinstellungen() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const panelEinstellungen = document.getElementById('u-cronjob');
	displayValues(unternehmen.NeolohnEinstellungen, '', panelEinstellungen, 'u-Cronjob-');
}

async function speichereCronjobEinstellungen() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const panelEinstellungen = document.getElementById('u-cronjob');
	collectValues(unternehmen.NeolohnEinstellungen, 'u-Cronjob-', panelEinstellungen);
	updateDebounceUnternehmen(unternehmen);
}

async function ladePostfachVerzeichnisse() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const panelEinstellungen = document.getElementById('u-verzeichnisse');
	// Wir zeigen hier nur die zusätzlichen Verzeichnisse an!
	displayArray(unternehmen.Verzeichnisse.filter((vz) => !vz.SystemVerzeichnis), 'u-verzeichnis-', panelEinstellungen);
}

async function speicherePostfachVerzeichnisse() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const panelEinstellungen = document.getElementById('u-verzeichnisse');
	// Verzeichnisse speichern
	unternehmen.Verzeichnisse.forEach((verzeichnis) => {
		// wir suchen die VerzeichnisID im Frontend und die zugehörigen Inputfelder.
		const zeile = panelEinstellungen.querySelector(`[aria-label="${verzeichnis._id}"]`);
		const zeileVerzeichnisName = zeile.querySelector('[aria-label="u-verzeichnis-VerzeichnisName"]').value;
		// einen leeren String wollen wir nicht als Namen haben
		verzeichnis.VerzeichnisName = zeileVerzeichnisName === '' ? verzeichnis.VerzeichnisName : zeileVerzeichnisName;
	});
	updateDebounceUnternehmen(unternehmen);
}

async function clickNeuesVerzeichnis() {
	const input = await SystemDialogService.instance.displayAsync('neu-verzeichnis-dialog');
	if (input.success) {
		const result = await stammdatenService.erstellePostfachVerzeichnis(input.data);
		if (result) {
			await ladePostfachVerzeichnisse();
		}
	}
}

async function confirmNeuesVerzeichnisDialog() {
	const verzeichnisName = document.getElementById('neu-verzeichnis-name').value;
	SystemDialogService.instance.confirm(verzeichnisName);
}

async function clickEntferneVerzeichnis(thisElement) {
	const verzeichnisID = thisElement.parentNode.getAttribute('aria-label');
	const result = await stammdatenService.entfernePostfachVerzeichnis(verzeichnisID);
	if (result) {
		await ladePostfachVerzeichnisse();
	}
}

async function ladeAlleEinstellungen() {
	await ladeAppEinstellungen();
	await ladeCronjobEinstellungen();
	await ladePostfachVerzeichnisse();
}

export {
	ladeEinsatzorte,
	ladeVerwaltung,
	holeFesteTextmarken,
	holeTextmarken,
	holeVorlagen,
	ladeBenutzerverwaltung,
	ladeSchriftverkehr,
	ladePausenmodelle,
	ladeAlleEinstellungen
};
