import _ from 'underscore';
import dayjs from '../../../shared/services/dayjs.js';
import stammdatenService from './services/stammdatenService.js';
import SystemDialogService from '../../../shared/services/systemDialogService.js';

window.myHandlers = window.myHandlers || {};
window.myHandlers.getLohnzettelHTML = getLohnzettelHTML;
window.myHandlers.getStundenzettelHTML = getStundenzettelHTML;

async function getLohnzettelHTML() {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    if (_.isEmpty(aktuellerMA)) {
        await SystemDialogService.instance.displayAsync('kein-ma-alert-dialog');
        return;
    }
    const aktuellerZeitraum = dayjs(document.getElementById('monats-auswahl').value).format('YYYY-MM-DD');
    const result = await stammdatenService.postBerichtMA(aktuellerMA._id, aktuellerZeitraum, 'lohnzettel');
    if (result) {
        // Zeige den Lohnzettel im HTML Viewer in der Oberfläche an
        document.getElementById('reportarea').innerHTML = result;
    }
}

async function getStundenzettelHTML() {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    if (_.isEmpty(aktuellerMA)) {
        await SystemDialogService.instance.displayAsync('kein-ma-alert-dialog');
        return;
    }
    const aktuellerZeitraum = dayjs(document.getElementById('monats-auswahl').value).format('YYYY-MM-DD');
    const result = await stammdatenService.postBerichtMA(aktuellerMA._id, aktuellerZeitraum, 'stundenzettel');
    if (result) {
        // Zeige den Lohnzettel im HTML Viewer in der Oberfläche an
        document.getElementById('reportarea').innerHTML = result;
    }
}

async function ladeBerichteMA() {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    document.getElementById('reportarea').innerHTML = '';
    if (_.isEmpty(aktuellerMA)) {
        return;
    }
    await getLohnzettelHTML();
}

export { ladeBerichteMA };
