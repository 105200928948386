window.app = window.app || {};
window.app.dialogs = window.app.dialogs || {};

/**
 * Zeigt ein Popups an.
 * Der Service verwaltet seine Instanzen selbständig => keine neue Initialisierungen mit new notwendig.
 * ```js
 * SystemDialogService.instance // gibt die Standardinstanz zurück.
 * SystemDialogService.byID('htmlID') // gibt eine Instanz zurück die an diese htmlID gebunden ist.
 * ```
 * Das HTML muss eine gewisse Formatierung aufweisen.
 * Es wird erwartet, dass es ein Kind Element mit der CSS Klasse app-dialog-content innerhalb des HTML Elements mit der übergebenen ID besitzt.
 * Generelle Benutzung mit Callbacks:
 * ```js
 * SystemDialogService.instance.display(htmlContentId).onConfirm(async (data) => {
 *		console.log(data);
 *	});
 * ```
 * Generelle Benutzung mit Async/await:
 * ```js
 * const data = await SystemDialogService.instance.displayAsync(htmlContentId)
 * console.log(data);
 * ```
 * Schließen des Dialogs:
 * ```js
 * SystemDialogService.instance.confirm('hier beliebige daten')
 * ```
 */
class SystemDialogService {
    /**
     * @returns gibt die Standard HTML PopupID zurück
     */
    static get popupID() {
        return 'app-dialog';
    }

    /**
     * Shortcut für:
     * ```js
     * SystemDialogService.byID(SystemDialogService.popupID);
     * ```
     */
    static get instance() {
        return SystemDialogService.byID(SystemDialogService.popupID, '10000');
    }

    /**
     * Speichert die Referenz auf den Dialog und gibt diese zurück.
     * @param {string} popupID htmlId des popup dialogs
     * @returns Referenz auf Dialog
     */
    static byID(popupID, zIndex) {
        if (!window.app.dialogs[popupID]) {
            window.app.dialogs[popupID] = new SystemDialogService(popupID, zIndex);
        }
        return window.app.dialogs[popupID];
    }

    /**
     * Dieser Constructor ist für technische Zwecke und nicht für die Verwendung bestimmt.
     * Zur Erzeugung von neuen Instanzen siehe:
     * ```js
     * SystemDialogService.instance
     * SystemDialogService.byID(popupID)
     * ```
     * @param {string} popupID
     */
    constructor(popupID, zIndex) {
        this.popupID = popupID;
        this.zIndex = zIndex;
        this.dialogRoot = document.getElementById(this.popupID);
        this.dialogRoot.style.zIndex = this.zIndex;
        this.confirmCallbacks = [];
        this.abortCallbacks = [];
    }

    /**
     * Zeigt einen bestimmten HTML Inhalt in dem popup Dialog an.
     * Um Zugriff auf die Input Daten des Users zu erhalten, muss die Funktion onConfirm verwendet werden.
     * @param {string} htmlContentID Id des HTML Contents
     * @param {object} options optionale Einstellungen, closeOnBackdrop (default: true) erlaubt ein abort beim Klicken auf dem Hintergrund.
     * @returns Instanz auf sich selbst für Methodchaining
     */
    display(htmlContentID, options) {
        this.abortController = new AbortController();
        options = options || { closeOnBackdrop: true };
        this.content = document.getElementById(htmlContentID);
        this.dialogRoot.getElementsByClassName('app-dialog-content')[0].appendChild(this.content);
        this.dialogRoot.getElementsByClassName('app-dialog-content')[0].children[0].style.display = 'flex';
        this.dialogRoot.classList.replace('invisible', 'visible');
        // focus the first input or button element after transition animation ends
        let firstTabElement = this.dialogRoot.querySelector('input');
        if (firstTabElement !== null) {
            setTimeout(() => {
                firstTabElement.focus();
                firstTabElement.select();
            }, 250);
        } else {
            firstTabElement = this.dialogRoot.querySelector('button');
            if (firstTabElement !== null) {
                setTimeout(() => {
                    firstTabElement.focus();
                }, 100);
            }
        }
        if (options.closeOnBackdrop) {
            this.abortCallback = this.abort.bind(this);
            this.dialogRoot.addEventListener('mousedown', this.abort.bind(this), { signal: this.abortController.signal });
            this.dialogRoot.addEventListener('keydown', this.closeOnEscape.bind(this), { signal: this.abortController.signal });
            this.dialogRoot.children[0].addEventListener('mousedown', this.stopPropagation.bind(this), { signal: this.abortController.signal });
        }
        this.dialogRoot.addEventListener('keydown', this.dialogKeys.bind(this), { signal: this.abortController.signal });
        return this;
    }

    /**
     * Ermöglicht die Nutzung von Async/await mit display() in dem ein Promise erstellt wird.
     * @param {string} htmlContentID Id des HTML Contents
     * @param {object} options optionale Einstellungen, closeOnBackdrop (default: true) erlaubt ein abort beim Klicken auf dem Hintergrund.
     * @returns Instanz auf sich selbst für Methodchaining
     */
    async displayAsync(htmlContentID, options) {
        return new Promise((resolve) => {
            this.onConfirm(resolve);
            this.onAbort(resolve);
            this.display(htmlContentID, options);
        });
    }

    /**
     * Schließt das Dialog Fenster mit erfolgreicher Eingabe und reicht die zu übertragenden Daten weiter.
     * @param {object} data die vom Dialog Fenster übertragen werden soll.
     */
    confirm(data) {
        this.dialogRoot.getElementsByClassName('app-dialog-content')[0].innerHTML = '';
        this.dialogRoot.classList.replace('visible', 'invisible');
        this.confirmCallbacks.forEach((cb) => {
            cb({ success: true, data });
        });
        document.getElementById('app-dialog-placeholder').appendChild(this.content);
        this.dispose();
    }

    /**
     * Schließt das Fenster nach Abbruch des Dialogs. Es werden keine Daten weitergetragen.
     */
    abort() {
        this.dialogRoot.getElementsByClassName('app-dialog-content')[0].innerHTML = '';
        this.dialogRoot.classList.replace('visible', 'invisible');
        this.abortCallbacks.forEach((cb) => {
            cb({ success: false });
        });
        document.getElementById('app-dialog-placeholder').appendChild(this.content);
        this.dispose();
    }

    onConfirm(confirmFn) {
        this.confirmCallbacks.push(confirmFn);
        return this;
    }

    onAbort(abortFn) {
        this.abortCallbacks.push(abortFn);
        return this;
    }

    // Verwaltet die key events in der Dialog Box
    dialogKeys(event) {
        // Wir wollen nur innerhalb des Dialogs Tabben, dafür müssen wir tabindex angeben.
        if (event.key === 'Tab') {
            event.preventDefault();
            const tabindex = event.target.tabIndex;
            const nextElement = this.dialogRoot.querySelector(`[tabindex="${tabindex + 1}"]`);
            if (nextElement !== null) {
                nextElement.focus();
            } else {
                this.dialogRoot.querySelector('[tabindex="0"]').focus();
            }
        }
    }

    dispose() {
        this.abortController.abort();
    }

    stopPropagation(event) {
        event.stopPropagation();
    }

    closeOnEscape(event) {
        if (event.key === 'Escape') {
            this.abort();
        }
    }
}

// Als Globale Variable speichern
window.myServices = window.myServices || {};
window.myServices.SystemDialogService = SystemDialogService;

export default SystemDialogService;
