/**
 * Hier kommt Code für EGFZ Geschichten hin
 * - Gutzeitkonto
 * - Urlaubskonto
 * - Mutterschutz
 * - Krank
 */

import _ from 'underscore';
import dayjs from '../../../shared/services/dayjs.js';
import { berechneUrlaubsmonate, validateZeitkonto } from './ma_daten.js';
import stammdatenService from './services/stammdatenService.js';
import {
    checkUrlaubsantragKollisionen,
    collectValues,
    displayArray,
    displayValues
} from './util.js';
import systemNachrichtService from './services/systemNachrichtService.js';
import SystemDialogService from '../../../shared/services/systemDialogService.js';
import schichtService from './services/schichtService.js';

window.myHandlers = window.myHandlers || {};
window.myHandlers.speichereUrlaubskonto = speichereUrlaubskonto;
window.myHandlers.updateUrlaubsantrag = updateUrlaubsantrag;
window.myHandlers.updateUrlaubZusatz = updateUrlaubZusatz;
window.myHandlers.updateUrlaubVorwert = updateUrlaubVorwert;
window.myHandlers.updateGutzeitVorwert = updateGutzeitVorwert;
window.myHandlers.updateGutzeitAbgegolten = updateGutzeitAbgegolten;

async function ladeZeitkonto(aktuellerMA) {
    if (!_.isEmpty(aktuellerMA)) {
        const journalKomplett = await stammdatenService.leseJournalHistorieMitarbeiter(aktuellerMA._id);
        // Lade Urlaubsdaten
        ladeUrlaubskonto(aktuellerMA);
        // Lade Gutzeitkonto
        ladeGutzeitkonto(aktuellerMA, journalKomplett);
        // Lade Kranktage Konto
        ladeKranktagekonto(journalKomplett);
    }
}

/**
 * Ladet die Urlaubswerte und -anträge in die Oberfläche
 * @param {object} aktuellerMA
 */
function ladeUrlaubskonto(aktuellerMA) {
    const zeitkontoHTML = document.getElementById('ma-Zeitkonto');
    const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
    displayValues(aktuelleBeschaeftigung.Urlaub, '', zeitkontoHTML, 'ma-');
    validateZeitkonto(aktuellerMA);
    const urlaubsantraege = aktuelleBeschaeftigung.Urlaubsantraege;
    const antragHTML = document.getElementById('ma-Urlaubsantrag');
    displayArray(urlaubsantraege, 'ma-ua-', antragHTML);
}

/**
 * Ladet alle Journaldaten des Jahres in denen die Gutzeiten vermerkt sind
 */
async function ladeGutzeitkonto(aktuellerMA, journalKomplett) {
    const vorwertInput = document.querySelector('[aria-label="ma-gz-GutzeitVorwert"]');
    const vorwertValue = aktuellerMA.Beschaeftigung[0]?.GutzeitVorwert ? aktuellerMA.Beschaeftigung[0].GutzeitVorwert : 0;
    vorwertInput.value = vorwertValue;
    // Wir wollen durch jeden Monat iterieren...
    const jahresAnfang = window.myVars.aktuellesMonatsDatum.startOf('year');
    const journalJahr = journalKomplett.filter((journal) => dayjs(journal.ZeitraumDate).isSame(jahresAnfang, 'year'));
    const gutzeitMonateDiv = document.getElementById('ma-gz-monate');
    gutzeitMonateDiv.innerHTML = '';
    const gutzeitTemplate = document.querySelector('[ma-gz-template]');
    for (let i = 0; i < 12; i++) {
        // Monat
        const tmpDate = jahresAnfang.add(i, 'months');
        const journalMonat = journalJahr.find((journal) => dayjs(journal.ZeitraumDate).isSame(tmpDate, 'month'));
        if (journalMonat) {
            const zeitraum = tmpDate.format('YYYY/MM');
            const gutzeitMonatHTML = gutzeitTemplate.content.cloneNode(true).children[0];
            gutzeitMonatHTML.id = journalMonat._id;
            gutzeitMonatHTML.setAttribute('aria-label', tmpDate.format('YYYY-MM-DD'));
            gutzeitMonatHTML.querySelector('[aria-label="ma-gz-GutzeitMonat"]').innerText = zeitraum;
            gutzeitMonatHTML.querySelector('[aria-label="ma-gz-GutzeitMinus"]').innerText = journalMonat.Mitarbeiter.Monatswerte.GutzeitMinus;
            gutzeitMonatHTML.querySelector('[aria-label="ma-gz-GutzeitPlus"]').innerText = journalMonat.Mitarbeiter.Monatswerte.GutzeitPlus;
            const gutzeitMonatAbgegolten = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0].GutzeitAbgegolten.find((gz) => gz.Zeitraum === tmpDate.format('YYYY-MM-DD'));
            const abgegoltenValue = gutzeitMonatAbgegolten ? gutzeitMonatAbgegolten.AbgegoltenStunden : 0;
            gutzeitMonatHTML.querySelector('[aria-label="ma-gz-GutzeitAbgegolten"]').value = abgegoltenValue;
            gutzeitMonatHTML.querySelector('[aria-label="ma-gz-GutzeitRest"]').innerText = -journalMonat.Mitarbeiter.Monatswerte.GutzeitMinus + journalMonat.Mitarbeiter.Monatswerte.GutzeitPlus - abgegoltenValue;
            gutzeitMonateDiv.appendChild(gutzeitMonatHTML);
        }
    }
    calcGutzeitSummen(aktuellerMA);
}

function calcGutzeitSummen(aktuellerMA) {
    // Plus
    const gutzeitAufbau = Array.from(document.querySelectorAll('[aria-label="ma-gz-GutzeitPlus"]'));
    const aufbauSumme = gutzeitAufbau.reduce((sum, gz) => sum + parseFloat(gz.innerText), 0);
    document.querySelector('[aria-label="ma-gz-GutzeitSummePlus"]').innerText = aufbauSumme;
    // Minus
    const gutzeitAbbau = Array.from(document.querySelectorAll('[aria-label="ma-gz-GutzeitMinus"]'));
    const abbauSumme = gutzeitAbbau.reduce((sum, gz) => sum + parseFloat(gz.innerText), 0);
    document.querySelector('[aria-label="ma-gz-GutzeitSummeMinus"]').innerText = abbauSumme;
    // Abgegolten
    const gutzeitAbgegolten = Array.from(document.querySelectorAll('[aria-label="ma-gz-GutzeitAbgegolten"]'));
    const abgSumme = gutzeitAbgegolten.reduce((sum, gz) => sum + parseFloat(gz.value), 0);
    document.querySelector('[aria-label="ma-gz-GutzeitSummeAbgegolten"]').innerText = abgSumme;
    // Reste
    const gutzeitenReste = Array.from(document.querySelectorAll('[aria-label="ma-gz-GutzeitRest"]'));
    const resteSumme = gutzeitenReste.reduce((sum, gz) => sum + parseFloat(gz.innerText), 0);
    const vorwert = aktuellerMA.Beschaeftigung[0].GutzeitVorwert;
    document.querySelector('[aria-label="ma-gz-GutzeitSummeRest"]').innerText = resteSumme + vorwert;
}

async function updateGutzeitAbgegolten(thisElement) {
    const urlaubsMonatHTML = thisElement.parentNode;
    const minus = parseFloat(urlaubsMonatHTML.querySelector('[aria-label="ma-gz-GutzeitMinus"]').innerText);
    const plus = parseFloat(urlaubsMonatHTML.querySelector('[aria-label="ma-gz-GutzeitPlus"]').innerText);
    // wir können maximal die Überstunden abgelten lassen...
    let abgeltenValue = parseFloat(thisElement.value);
    if (Number.isNaN(abgeltenValue) || abgeltenValue < 0) {
        systemNachrichtService.zeigeKleineNachricht('Abgegolten automatisch auf 0 gesetzt!', 0);
        abgeltenValue = 0;
        thisElement.value = 0;
    }
    thisElement.value = abgeltenValue;
    const rest = plus - minus - abgeltenValue;
    urlaubsMonatHTML.querySelector('[aria-label="ma-gz-GutzeitRest"]').innerText = rest;
    calcGutzeitSummen(stammdatenService.aktuellerMitarbeiter);
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const gutzeitAbgeltung = aktuellerMA.Beschaeftigung[0].GutzeitAbgegolten;
    const zeitraum = thisElement.parentNode.getAttribute('aria-label');
    const abgeltungsmonat = gutzeitAbgeltung.find((gz) => gz.Zeitraum === zeitraum);
    if (abgeltungsmonat) {
        abgeltungsmonat.AbgegoltenStunden = abgeltenValue;
    } else {
        gutzeitAbgeltung.push({ Zeitraum: zeitraum, AbgegoltenStunden: abgeltenValue });
    }
    await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
}

async function updateGutzeitVorwert(thisElement) {
    // Um eine einfache Minuseingabe zu ermöglichen, müssen wir ein Löschen des Inputs erlauben
    if (thisElement.value === '-' || thisElement.value === '') {
        return;
    }
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    let vorwert =  parseFloat(thisElement.value);
    if (Number.isNaN(vorwert)) {
        systemNachrichtService.zeigeKleineNachricht('Gutzeit Vorwert automatisch auf 0 gesetzt!', 0);
        vorwert = 0;
        thisElement.value = 0;
    }
    aktuellerMA.Beschaeftigung[0].GutzeitVorwert = vorwert;
    await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
    calcGutzeitSummen(aktuellerMA);
}

/**
 * Berechnet die Urlaubsansprüche und Summen neu und aktualisiert diese.
 */
async function speichereUrlaubskonto() {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
    // Die Summe des Urlaubsanspruch sowie die verbleibenden Urlaubstage werden von Zusatz- oder Vorwertänderungen betroffen und müssen angepasst werden...
    aktuelleBeschaeftigung.Urlaub.UrlaubsanspruchSumme = aktuelleBeschaeftigung.Urlaub.UrlaubsanspruchZusatzLaufendesJahr + aktuelleBeschaeftigung.Urlaub.UrlaubsanspruchLaufendesJahr + aktuelleBeschaeftigung.Urlaub.ResturlaubVorwert;
    aktuelleBeschaeftigung.Urlaub.ResturlaubLaufendesJahr = aktuelleBeschaeftigung.Urlaub.UrlaubsanspruchSumme - aktuelleBeschaeftigung.Urlaub.UrlaubGenehmigtSumme;
    aktuellerMA.Uebersicht.Resturlaub = aktuelleBeschaeftigung.Urlaub.ResturlaubLaufendesJahr;
    document.body.querySelector('[aria-label="ma-UrlaubsanspruchSumme"').value = aktuelleBeschaeftigung.Urlaub.UrlaubsanspruchSumme;
    document.body.querySelector('[aria-label="ma-ResturlaubLaufendesJahr"').value = aktuelleBeschaeftigung.Urlaub.ResturlaubLaufendesJahr;
    const urlaubHTML = document.getElementById('ma-Urlaubskonto');
    collectValues(aktuelleBeschaeftigung.Urlaub, 'ma-', urlaubHTML);
    await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
    validateZeitkonto(aktuellerMA);
}

/**
 * Updated den UrlaubsanspruchZusatzProJahr und -LaufendesJahr.
 * @param {HTMLElement} thisElement input feld des Urlaub Zusatz pro Jahr
 */
async function updateUrlaubZusatz(thisElement) {
    let urlaubszusatzJahr = parseInt(thisElement.value, 10);
    const aktuelleBeschaeftigung = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0];
    if (Number.isNaN(urlaubszusatzJahr) || urlaubszusatzJahr < 0) {
        systemNachrichtService.zeigeKleineNachricht('Urlaubsanspruchszusatz automatisch auf 0 gesetzt!', 0);
        thisElement.value = 0;
        urlaubszusatzJahr = 0;
    }
    const urlaubProMonat = urlaubszusatzJahr / 12;
    const urlaubsmonate = berechneUrlaubsmonate(aktuelleBeschaeftigung, false);
    const urlaubsanspruchZusatzLaufend = Math.ceil(urlaubsmonate * urlaubProMonat);
    aktuelleBeschaeftigung.Urlaub.UrlaubsanspruchZusatzLaufendesJahr = urlaubsanspruchZusatzLaufend;
    document.body.querySelector('[aria-label="ma-UrlaubsanspruchZusatzProJahr"]').value = urlaubszusatzJahr;
    document.body.querySelector('[aria-label="ma-UrlaubsanspruchZusatzLaufendesJahr"]').value = urlaubsanspruchZusatzLaufend;
    await speichereUrlaubskonto();
}

/**
 * Updated die Resturlaub Vorwerte
 * @param {HTMLElement} thisElement input feld des Urlaub Vorwerts.
 */
async function updateUrlaubVorwert(thisElement) {
    // Um eine einfache Minuseingabe zu ermöglichen, müssen wir ein Löschen des Inputs erlauben
    if (thisElement.value === '-' || thisElement.value === '') {
        return;
    }
    let resturlaubVorjahr = parseInt(thisElement.value, 10);
    if (Number.isNaN(resturlaubVorjahr)) {
        thisElement.value = 0;
        resturlaubVorjahr = 0;
        systemNachrichtService.zeigeKleineNachricht('Resturlaub automatisch auf 0 gesetzt!', 0);
    }
    const aktuelleBeschaeftigung = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0];
    const urlaubVerbleibend = document.querySelector('[aria-label="ma-ResturlaubLaufendesJahr"]');
    const differenz = aktuelleBeschaeftigung.Urlaub.ResturlaubVorwert - resturlaubVorjahr;
    const result = parseInt(urlaubVerbleibend.value, 10) - differenz;
    urlaubVerbleibend.value = result;
    aktuelleBeschaeftigung.Urlaub.ResturlaubVorwert = resturlaubVorjahr;
    await speichereUrlaubskonto();
}

/**
 * Updated den Urlaubsantragsstatus, je nach dem welche Aktion ausgeführt wurde
 * @param {HTMLElement} thisElement Urlaub antrag akzeptieren/ablehnen/reset button
 * @param {string} statusUpdate abgelehnt, offen, genehmigt
 */
async function updateUrlaubsantrag(thisElement, statusUpdate) {
    const urlaubsantragHTML = document.getElementById(`ma-ua-${thisElement.id}`);
    const urlaubStatus = urlaubsantragHTML.querySelector('[aria-label="ma-ua-UrlaubStatus"]');
    if (urlaubStatus.innerText === statusUpdate) {
        systemNachrichtService.zeigeKleineNachricht(`Der Status des Urlaubantrags ist bereits ${statusUpdate}!`);
        return;
    }
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    // Wir suchen den Urlaubsantrag im Mitarbeiter
    const urlaubsantrag = aktuellerMA.Beschaeftigung[0].Urlaubsantraege.find((ua) => ua._id === thisElement.id);
    if (!urlaubsantrag) {
        systemNachrichtService.zeigeKleineNachricht('Urlaubsantrag nicht gefunden!', -1);
        return;
    }
    // Hier müssten vor dem Speichervorgang die Urlaubstage als Schichten ein oder ausgetragen werden...
    if (urlaubsantrag.UrlaubStatus !== 'genehmigt' && statusUpdate === 'genehmigt') {
        const kollisionsIndex = checkUrlaubsantragKollisionen(aktuellerMA, dayjs(urlaubsantrag.UrlaubVon), dayjs(urlaubsantrag.UrlaubBis), urlaubsantrag._id);
        if (kollisionsIndex >= 0) {
            await SystemDialogService.instance.displayAsync('zeiten-urlaub-kollision-dialog');
            return;
        }
        await schichtService.erstelleUrlaubAusAntrag(aktuellerMA._id, urlaubsantrag._id, statusUpdate);
        await ladeZeitkonto(stammdatenService.aktuellerMitarbeiter);
        return;
    }
    // Wenn wir einen genehmigten Urlaubseintrag widerrufen (offen oder ablehnen), müssen wir die Urlaubstage austragen.
    if (urlaubsantrag.UrlaubStatus === 'genehmigt' && statusUpdate !== 'genehmigt') {
        await schichtService.entferneUrlaub(aktuellerMA._id, urlaubsantrag._id, statusUpdate);
        await ladeZeitkonto(stammdatenService.aktuellerMitarbeiter);
        return;
    }
    // aktualisiere und speichere urlaubsantragstatus
    urlaubStatus.innerText = statusUpdate;
    urlaubsantrag.UrlaubStatus = statusUpdate;
    await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
}

/**
 * Ladet alle Journaldaten des Jahres in denen die Kranktage vermerkt sind
 */
async function ladeKranktagekonto(journalHistorie) {
    // Wir wollen durch jeden Monat iterieren...
    const jahresAnfang = window.myVars.aktuellesMonatsDatum.startOf('year');
    const journalJahr = journalHistorie.filter((journal) => dayjs(journal.ZeitraumDate).isSame(jahresAnfang, 'year'));
    const kranktageMonateDiv = document.getElementById('ma-kt-monate');
    kranktageMonateDiv.innerHTML = '';
    const kranktageTemplate = document.querySelector('[ma-kt-template]');
    for (let i = 0; i < 12; i++) {
        // Monat
        const tmpDate = jahresAnfang.add(i, 'months');
        const journalMonat = journalJahr.find((journal) => dayjs(journal.ZeitraumDate).isSame(tmpDate, 'month'));
        if (journalMonat) {
            const zeitraum = tmpDate.format('YYYY/MM');
            const krankTageMonatHTML = kranktageTemplate.content.cloneNode(true).children[0];
            krankTageMonatHTML.id = journalMonat._id;
            krankTageMonatHTML.querySelector('[aria-label="ma-kt-KranktageMonat"]').innerText = zeitraum;
            displayValues(journalMonat.Mitarbeiter.Monatswerte, '', krankTageMonatHTML, 'ma-kt-');
            kranktageMonateDiv.appendChild(krankTageMonatHTML);
        }
    }
    calcKranktageSummen();
}

function calcKranktageSummen() {
    // Kranktage
    const kranktage = Array.from(document.querySelectorAll('[aria-label="ma-kt-Kranktage"]'));
    const kranktageSum = kranktage.reduce((sum, gz) => sum + parseFloat(gz.innerText), 0);
    document.querySelector('[aria-label="ma-kt-KranktageSumme"]').innerText = kranktageSum;
    // KindKrank
    const kindKrank = Array.from(document.querySelectorAll('[aria-label="ma-kt-KindKrank"]'));
    const kindKrankSum = kindKrank.reduce((sum, gz) => sum + parseFloat(gz.innerText), 0);
    document.querySelector('[aria-label="ma-kt-KindKrankSumme"]').innerText = kindKrankSum;
    // Krank6Wochen
    const krank6Wochen = Array.from(document.querySelectorAll('[aria-label="ma-kt-Krank6Wochen"]'));
    const krank6WochenSum = krank6Wochen.reduce((sum, gz) => sum + parseFloat(gz.innerText), 0);
    document.querySelector('[aria-label="ma-kt-Krank6WochenSumme"]').innerText = krank6WochenSum;
    // UnentschuldigtKrank
    const unentschuldigtKrank = Array.from(document.querySelectorAll('[aria-label="ma-kt-UnentschuldigtKrank"]'));
    const unentschuldigtKrankSum = unentschuldigtKrank.reduce((sum, gz) => sum + parseFloat(gz.innerText), 0);
    document.querySelector('[aria-label="ma-kt-UnentschuldigtKrankSumme"]').innerText = unentschuldigtKrankSum;
    // KrankErste4Wochen (KO)
    const krankErste4Wochen = Array.from(document.querySelectorAll('[aria-label="ma-kt-KO"]'));
    const krankErste4WochenSum = krankErste4Wochen.reduce((sum, gz) => sum + parseFloat(gz.innerText), 0);
    document.querySelector('[aria-label="ma-kt-KOSumme"]').innerText = krankErste4WochenSum;
    // KranktageAlle
    const kranktageAlle = Array.from(document.querySelectorAll('[aria-label="ma-kt-KranktageAlle"]'));
    const kranktageAlleSum = kranktageAlle.reduce((sum, gz) => sum + parseFloat(gz.innerText), 0);
    document.querySelector('[aria-label="ma-kt-KranktageAlleSumme"]').innerText = kranktageAlleSum;
}

export {
    ladeZeitkonto
};
